<template>
    <div
        class="field"
        :class="className"
    >
        <div class="field__title">
            <slot name="label">
                <label :for="idName">{{ label }}{{ required ? ' *' : '' }}</label>
            </slot>
        </div>
        <div class="field__input">
            <slot name="input">
                <template v-if="type === 'select'">
                    <multiSelect
                        :id="idName"
                        v-model.lazy="val"
                        :options="options"
                        :name="name"
                        :disabled="disabled"
                        :readonly="readonly"
                        track-by="name"
                        label="name"
                        :searchable="searchable"
                        :close-on-select="true"
                        :show-labels="false"
                        :placeholder="placeholder"
                    />
                </template>
                <template v-else-if="type === 'radio'">
                    <template v-for="(option, idx) in options">
                        <input
                            :id="idNameIdx(idx)"
                            :key="idNameIdx(idx)"
                            v-model.lazy="val"
                            :name="fieldName"
                            :value="option.value"
                            :disabled="disabled || option.disabled"
                            :readonly="readonly"
                            type="radio"
                            class="radiobox"
                        >
                        <label
                            :key="idNameIdx(idx)"
                            :for="idNameIdx(idx)"
                        >{{ option.name }}</label>
                    </template>
                </template>
                <template v-else-if="type === 'checkbox'">
                    <template v-for="(option, idx) in options">
                        <input
                            :id="idNameIdx(idx)"
                            :key="idNameIdx(idx)"
                            v-model.lazy="val"
                            :name="fieldName"
                            :value="option.value"
                            :disabled="disabled || option.disabled"
                            :readonly="readonly"
                            type="checkbox"
                            class="checkbox"
                        >
                        <label
                            :key="idNameIdx(idx)"
                            :for="idNameIdx(idx)"
                        >
                            {{ option.name }}
                        </label>
                    </template>
                </template>
                <template v-else-if="type === 'textarea'">
                    <textarea
                        :id="idName"
                        v-model.lazy="val"
                        :name="name"
                        :disabled="disabled"
                        :readonly="readonly"
                        :placeholder="placeholder"
                        class="textarea"
                    />
                </template>
                <template v-else-if="type === 'phone'">
                    <mask-input
                        :id="idName"
                        v-model="val"
                        :name="name"
                        mask="+7 (999) 999-9999"
                        :disabled="disabled"
                        :readonly="readonly"
                        :placeholder="placeholder"
                        type="text"
                        class="input"
                    />
                </template>
                <template v-else-if="type === 'date'">
                    <date-input
                        :id="idName"
                        v-model="val"
                        :name="name"
                        :disabled="disabled"
                        :readonly="readonly"
                        :placeholder="placeholder"
                        type="text"
                        class="input"
                    />
                </template>
                <template v-else-if="type === 'mask'">
                    <mask-input
                        :id="idName"
                        v-model="val"
                        :name="name"
                        :mask="mask"
                        :disabled="disabled"
                        :readonly="readonly"
                        :placeholder="placeholder"
                        type="text"
                        class="input"
                    />
                </template>
                <template v-else-if="type === 'email'">
                    <mask-input
                        :id="idName"
                        v-model="val"
                        :name="name"
                        mask="email"
                        :disabled="disabled"
                        :readonly="readonly"
                        :placeholder="placeholder"
                        type="text"
                        class="input"
                    />
                </template>
                <template v-else>
                    <input
                        :id="idName"
                        v-model.lazy="val"
                        :name="name"
                        :disabled="disabled"
                        :readonly="readonly"
                        :placeholder="placeholder"
                        :type="type"
                        class="input"
                    >
                </template>
            </slot>
        </div>
        <div
            v-if="errors"
            class="field__error"
        >
            <ul class="field__error-list">
                <li
                    v-for="errorItem in errors"
                    :key="errorItem"
                >
                    {{ errorItem }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import dateInput from './inputs/date-input.vue';
    import maskInput from './inputs/mask-input.vue';

    export default {
        components: {
            dateInput,
            maskInput
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            type: {
                type: String,
                default: 'text'
            },
            value: {
                type: [String, Number, Boolean, Array, Object],
                default() {
                    return ''
                },
                useDefaultForNull: true
            },
            label: {
                type: String,
                default: undefined
            },
            name: {
                type: String,
                default: undefined
            },
            id: {
                type: String,
                default: undefined
            },
            placeholder: {
                type: String,
                default: undefined
            },
            disabled: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            classVal: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                default() { return []; }
            },
            required: {
                type: Boolean,
                default: false
            },
            mask: {
                type: String,
                default: ''
            },
            full: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            searchable: {
                type: Boolean,
                default: true
            },
            errors: {
                type: [Boolean, String, Array],
                default: false
            }
        },
        data() {
            return {
                val: this.value
            };
        },
        computed: {
            className() {
                let value = 'field--type_' + this.type;
                if (this.full) {
                    value += ' m--full';
                } else {
                    value += ' m--half';
                }
                if (this.small) {
                    value += ' m--small';
                }
                if (this.required) {
                    value += ' field--required';
                }
                if (this.error) {
                    value += ' is-error';
                }
                if (this.classVal) {
                    value += ` ${this.classVal}`
                }
                return value;
            },
            idName() {
                return this.id || this.name || 'field__' + this.random;
            },
            fieldName() {
                return this.name || 'field__' + this.random;
            },
            random() {
                return Math.floor(Math.random() * 32768);
            },
            error() {
                return this.errors
            }
        },
        watch: {
            val(value) {
                this.change(value);
            },
            value(value) {
                this.val = value;
            }
        },
        methods: {
            idNameIdx(idx) {
                return this.idName + '--' + (idx + 1);
            },
            change(value) {
                this.$emit('change', value);
            }
        }
    };
</script>
