<template>
    <div class="account">
        <AccountMenu />
        <div
            v-show="documents && documents.length"
            class="account__docs-table table"
        >
            <div class="account__docs-table-header table__header">
                <div class="table__header-cell">
                    Номер договора
                </div>
                <div class="table__header-cell">
                    Дата заключения
                </div>
                <div class="table__header-cell">
                    Скан договора
                </div>
                <div class="table__header-cell">
                    Ваш комментарий
                </div>
                <div class="table__header-cell">
                    Статус
                </div>
                <div class="table__header-cell" />
            </div>
            <AccountDocsItem
                v-for="doc in documents"
                :key="doc.id"
                :doc="doc"
                @editDoc="editDoc"
            />
        </div>
    </div>
</template>

<script>
    import AccountMenu from "../components/AccountMenu";
    import AccountDocsItem from "@/components/AccountDocsItem";
    import VendorDocsEditModal from "@/components/VendorDocsEditModal";
    import { users } from "@/services";

    export default {
        name: "AccountDocs",
        components :{
            AccountMenu,
            AccountDocsItem
        },
        data() {
            return {
                documents: []
            }
        },
        beforeMount() {
            this.getDocuments();
        },
        methods: {
            getDocuments() {
                users.getDocuments()
                    .then(response => this.documents = response);
            },
            editDoc(doc) {
                this.$modal.show(VendorDocsEditModal, {
                    mode: 'edit',
                    initDoc: doc,
                    id: this.$store.state.id
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getDocuments(this.id)
                });
            },
        }
    }
</script>
