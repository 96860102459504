<template>
    <div class="account">
        <AccountMenu />
        <div class="account__buttons">
            <button
                class="button button-red"
                @click="addDriver"
            >
                <span class="m--plus">+</span> Добавить водителя
            </button>
        </div>
        <div
            v-show="drivers && drivers.length"
            class="account__drivers-table table"
        >
            <div class="account__drivers-table-header table__header">
                <div class="table__header-cell">
                    ФИО
                </div>
                <div class="table__header-cell">
                    Паспорт
                </div>
                <div class="table__header-cell">
                    Водит. удо&shy;сто&shy;верение
                </div>
                <div class="table__header-cell">
                    Телефон
                </div>
                <div class="table__header-cell">
                    Стаж, лет
                </div>
                <div class="table__header-cell">
                    Статус
                </div>
                <div class="table__header-cell">
                    Ваш ком&shy;мен&shy;тарий
                </div>
                <div class="table__header-cell" />
            </div>
            <AccountDriversItem
                v-for="driver in drivers"
                :key="driver.id"
                :driver="driver"
                @editDriver="editDriver"
            />
        </div>
    </div>
</template>

<script>
    import AccountMenu from "../components/AccountMenu";
    import AccountDriversItem from "@/components/AccountDriversItem";
    import AddDriverModal from "../components/AddDriverModal";
    import { users } from "@/services";

    export default {
        name: "AccountDrivers",
        components :{
            AccountMenu,
            AccountDriversItem
        },
        data() {
            return {
                drivers: []
            }
        },
        beforeMount() {
            this.getDrivers();
        },
        methods: {
            getDrivers() {
                users.getDrivers()
                    .then(response => this.drivers = response);
            },
            addDriver() {
                this.$modal.show(AddDriverModal, {
                    mode: 'create'
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getDrivers()
                });
            },
            editDriver(driver) {
                this.$modal.show(AddDriverModal, { 
                    mode: 'edit', 
                    initDriver: driver
                },{
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getDrivers()
                });
            }
        }
    }
</script>