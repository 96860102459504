<template>
    <div class="vendors">
        <div class="vendors__buttons">
            <button
                class="vendors__button button button-red"
                @click="addVendor"
            >
                <span class="m--plus">+</span> Добавить транспортную
                компанию
            </button>
        </div>
        <div
            v-if="vendors.length > 0"
            class="vendors__table table"
        >
            <div class="vendors__table-header table__header">
                <div class="vendors__table-header-cell table__header-cell">
                    Логин
                </div>
                <div class="vendors__table-header-cell table__header-cell">
                    Название поставщика
                </div>
                <div class="vendors__table-header-cell table__header-cell">
                    Контакты
                </div>
                <div class="vendors__table-header-cell table__header-cell">
                    Горо&shy;да
                </div>
                <div class="vendors__table-header-cell table__header-cell">
                    Маши&shy;ны
                </div>
                <div class="vendors__table-header-cell table__header-cell">
                    Води&shy;тели
                </div>
                <div class="vendors__table-header-cell table__header-cell">
                    Рей&shy;тинг
                </div>
                <div class="vendors__table-header-cell table__header-cell">
                    Сдел&shy;ки
                </div>
                <div class="vendors__table-header-cell table__header-cell">
                    Актив&shy;ность
                </div>
                <div class="vendors__table-header-cell table__header-cell m--flex m--nopadding" />
            </div>
            <VendorItem
                v-for="vendor in vendors"
                :key="vendor.id"
                :vendor="vendor"
                @editVendor="editVendor"
                @setVendorStatus="setVendorStatus"
            />
        </div>
        <div
            v-else
            class="account__card"
        >
            Поставщики не были найдены
        </div>
    </div>
</template>

<script>
    import VendorEditModal from "@/components/VendorEditModal.vue";
    import VendorItem from "@/components/VendorItem";
    import { users } from "@/services";

    export default {
        name: "Vendors",
        components: {
            VendorItem
        },
        data() {
            return {
                vendors: []
            }
        },
        created() {
            this.getVendors()
        },
        methods: {
            getVendors() {
                users.getVendors()
                    .then(response => this.vendors = response);
            },
            addVendor() {
                this.$modal.show(VendorEditModal, {
                    mode: 'create'
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getVendors()
                });
            },
            editVendor(vendor) {
                this.$modal.show(VendorEditModal, { 
                    mode: 'edit', 
                    initVendor: vendor 
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getVendors()
                });
            },
            setVendorStatus(vendorId, status) {
                users.setVendorActive(vendorId, status)
                    .then(() => {
                        this.getVendors();
                    });
            }
        }

    }
</script>
