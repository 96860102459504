<template>
    <div class="modal">
        <div class="modal__header h1">
            {{ mode==='create' ? 'Добавить договор' : 'Редактировать договор' }}
        </div>
        <form
            id="modal_form"
            class="modal__body modal__form"
            @submit.prevent="save"
        >
            <div class="field__row">
                <field
                    v-model="doc.num"
                    type="text"
                    name="num"
                    label="Номер договора"
                    placeholder="Введите номер"
                    :small="true"
                    :required="true"
                    :readonly="!isManager"
                    :errors="errors.num"
                />
                <field
                    v-model="doc.sign_date"
                    type="date"
                    name="sign_date"
                    label="Дата заключения"
                    placeholder="Введите номер"
                    :small="true"
                    :required="true"
                    :readonly="!isManager"
                    :errors="errors.sign_date"
                />
            </div>
            <div
                class="field m--full"
                :class="errors.file_scan ? 'is-error' : ''"
            >
                <div class="field__title">
                    Скан документа
                </div>
                <div class="field__input">
                    <input
                        ref="file"
                        type="file"
                        name="file"
                        :readonly="!isManager"
                        @change="handleFileUpload()"
                    >
                </div>
                <div
                    v-if="errors.file_scan"
                    class="field__error"
                >
                    <ul class="field__error-list">
                        <li
                            v-for="error in errors.file_scan"
                            :key="error"
                        >
                            {{ error }}
                        </li>
                    </ul>
                </div>
            </div>
            <field
                v-model="doc.comment"
                type="textarea"
                name="comment"
                label="Комментарий"
                placeholder="Введите комментарий"
                :full="true"
                :readonly="isManager"
                :errors="errors.comment"
            />
            <field
                v-model="doc.manager_comment"
                type="textarea"
                name="manager_comment"
                label="Комментарий менеджера"
                placeholder="Введите комментарий"
                :full="true"
                :readonly="!isManager"
                :errors="errors.manager_comment"
            />
        </form>

        <div class="modal__footer m--flex">
            <button
                class="button button-red"
                form="modal_form"
                type="submit"
            >
                Сохранить
            </button>
            <button
                class="button buttom-transparent"
                @click="cancel"
            >
                Отменить
            </button>
        </div>
    </div>
</template>

<script>
    import {users} from "@/services";
    import field from "@/components/field";

    export default {
        name: "VendorDocsEditModal",
        components: {
            field
        },
        props: {
            mode: {
                type: String,
                default: 'create'
            },
            initDoc: {
                type: Object,
                default() { return {
                    num: '',
                    sign_date: '',
                    comment: '',
                    manager_comment: ''
                }; }
            },
            id: {
                type: Number,
                default: 0
            },
            isManager: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                doc: this.initDoc,
                errors: {}
            }
        },
        created() {
            if (this.initDoc.file_scan) {
                this.appendFile(this.initDoc.file_scan)
            }
        },
        methods: {
            async fetchImage(url, fileType) {
                const data = await fetch(url);
                const buffer = await data.arrayBuffer();
                return new Blob([buffer], {type: fileType});
            },
            async appendFile(url) {
                let fileName = url.substring(url.lastIndexOf("/") + 1);
                let fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
                const blob = await this.fetchImage(url, fileType);
                const file = new File([blob], fileName)
                const dT = new ClipboardEvent('').clipboardData || new DataTransfer();
                dT.items.add(file);
                this.$refs.file.files = dT.files;
            },
            handleFileUpload() {
                this.file = this.$refs.file.files[0];
            },
            cancel() {
                this.close();
            },
            close() {
                this.$emit('close');
            },
            save: function () {
                let formData = new FormData();
                formData.append('file_scan', this.file || '');
                formData.append('sign_date', this.doc.sign_date || '');
                formData.append('num', this.doc.num || '');
                formData.append('comment', this.doc.comment || '');
                formData.append('publication', typeof this.initDoc.publication === 'boolean' ? this.initDoc.publication : true );
                formData.append('manager_comment', this.doc.manager_comment || '');
                switch (this.mode) {
                case 'create':
                    users.addDoc(this.id, formData)
                        .then(() => this.close())
                        .catch((error) => {
                            if (error && error.response && error.response.data) {
                                this.errors = error.response.data;
                            }
                        })
                    break;
                case 'edit':
                    users.updateDoc(this.doc.id, formData)
                        .then(() => this.close())
                        .catch((error) => {
                            if (error && error.response && error.response.data) {
                                this.errors = error.response.data;
                            }
                        })
                    break;
                }
            }
        }
    }
</script>

<style scoped>
    .vm--modal {
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24), 0px 0px 46px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
    }

    .pseudo-field {
        margin-top: 42px;
    }

    textarea {
        height: 100px;
    }
</style>