<template>
    <div
        class="vendor__cars-table-row table__row"
        :class="itemOpened ? 'is-open' : ''"
    >
        <div class="table__row-cell m--header m--mobile-show">
            {{ car.brand }}&nbsp;<strong>{{ car.gos_num }}</strong>
            <div 
                class="vendor__table-row-slide table__row-slide" 
                @click.stop="slide"
            />
        </div>
        <div class="table__row-cell m--mobile-hide">
            <div class="vendor__cars-table-row-title table__row-title">
                Марка/ Модель
            </div>
            {{ car.brand }}
        </div>
        <div class="table__row-cell m--mobile-hide">
            <div class="vendor__cars-table-row-title table__row-title">
                Гос. номер
            </div>
            {{ car.gos_num }}
        </div>
        <div class="table__row-cell">
            <div class="vendor__cars-table-row-title table__row-title">
                Номер прицепа
            </div>
            {{ car.trailer_num }}
        </div>
        <div class="table__row-cell m--center">
            <div class="vendor__cars-table-row-title table__row-title">
                Грузо&shy;подъ&shy;емность, т
            </div>
            {{ car.carrying }}
        </div>
        <div class="table__row-cell m--center">
            <div class="vendor__cars-table-row-title table__row-title">
                Тип вы&shy;грузки
            </div>
            {{ car.unloading }}
        </div>
        <div class="table__row-cell m--center">
            <div class="vendor__cars-table-row-title table__row-title">
                Тип ТС
            </div>
            {{ car.ts_type }}
        </div>
        <div class="table__row-cell m--center">
            <div class="vendor__cars-table-row-title table__row-title">
                Ваш ком&shy;мен&shy;тарий
            </div>
            {{ car.comment }}
        </div>
        <div class="table__row-cell m--flex m--nopadding">
            <div
                class="m--icon m--gear"
                @click="$emit('editCar', car)"
            />
            <div
                :class="car.publication ? 'm--icon m--lock-open' : 'm--icon m--lock-closed'"
                @click="$emit('setCarStatus', car.id, !car.publication)"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            car: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                itemOpened: false
            }
        },
        methods: {
            slide() {
                this.itemOpened = !this.itemOpened;
            }
        }
    }
</script>
