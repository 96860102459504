<template>
    <div class="vendor">
        <div class="vendor__header h1">
            {{ companyName }}
        </div>
        <VendorMenu
            :id="id"
            :name="companyName"
        />
        <div
            v-if="filteredRegions && filteredRegions.length"
            class="account__card"
        >
            <div
                v-for="region in filteredRegions"
                :key="region.id"
                class="region"
            >
                <div class="region-label">
                    {{ region.name }}
                </div>
                <div
                    v-for="city in region.cities
                        .filter(c => userCityIds.includes(c.id))"
                    :key="city.id"
                    class="city-label"
                >
                    <span class="city-name">{{ city.name }}</span>
                </div>
            </div>
        </div>
        <div
            v-else
            class="account__card"
        >
            Поставщик не предоставил информацию о городах
        </div>
    </div>
</template>

<script>
    import VendorMenu from "@/components/VendorMenu";
    import { users, country } from "@/services"

    export default {
        name: "AccountCities",
        components: {
            VendorMenu
        },
        props: {
            name: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                cities: [],
                countries: [],
                regions: [],
                userCityIds: [],
                id: Number.parseInt(this.$route.params.id),
                companyName: ''
            }
        },
        computed: {
            getRegions() {
                return (this.countries || [])
                    .flatMap(c => (c.regions || [])
                        .concat(c.districts
                            .flatMap(d => d.regions || [])));
            },
            filteredRegions() {
                return this.getRegions
                    .filter(r => (r.cities || [])
                        .some(c => this.userCityIds.includes(c.id)));
            }
        },
        beforeMount() {
            this.getCities();
            if (this.name) {
                this.companyName = this.name;
            } else {
                this.getAccountInfo(this.id)
            }
        },
        methods: {
            getAccountInfo(id) {
                users.getProfile(id).then((res) => {
                    this.companyName = res.company.company_name
                }).catch((error) => {
                    console.log(error);
                })
            },
            getCities() {
                country.getCities()
                    .then(response => {
                        this.countries = response;
                        this.getUserCities(this.id);
                    });
            },
            getUserCities(id) {
                users.getVendorCities(id)
                    .then(res => {
                        this.resetUserCities(res.city_notices);
                        this.regions = this.getRegions;
                    });
            },
            resetUserCities(ids) {
                this.userCityIds = ids;
            }
        }
    }
</script>
