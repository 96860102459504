<template>
    <div class="modal">
        <div class="modal__header h1">
            Время подачи машины
        </div>
        <div class="modal__body modal__form">
            <field
                v-model="time"
                :options="timeOptions"
                type="select"
                name="time"
                label="Время подачи"
                placeholder="00:00"
                :searcheable="false"
                :small="true"
                :errors="errors.time"
            />
        </div>
        <div class="modal__footer m--flex">
            <button
                class="button button-red"
                @click="save"
            >
                Сохранить
            </button>
            <button
                class="button button-transparent"
                @click="cancel"
            >
                Отменить
            </button>
        </div>
    </div>
</template>

<script>
    import field from "@/components/field";
    import { app } from "@/services";

    export default {
        name: "SelectTimeModal",
        components: {
            field
        },
        props: {
            initTime: {
                type: Object,
                default() { return undefined }
            },
            dateStart: {
                type: String,
                default: ''
            },
            tenderId: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                time: {},
                timeOptions: [],
                errors: {}
            }
        },
        created() {
            this.getTimeDelivery();
        },
        methods: {
            getTimeDelivery() {
                app.getTimeDelivery(this.tenderId)
                    .then((res) => {
                        this.timeOptions = res.map(item => {
                            return {
                                name: item.time,
                                value: item.time
                            };
                        });
                        if (this.initTime) {
                            this.time = this.timeOptions.find(x => x.value === this.initTime.time);
                        } else {
                            this.time = this.timeOptions.length > 0 ? this.timeOptions[0] : ''
                        }
                    })
                    .catch((error) => {
                        if (error && error.response && error.response.data) {
                            this.errors = error.response.data;
                        }
                    });
            },
            save() {
                this.clearErrors();
                app.setupTender(this.tenderId, null, null, {
                    time: this.time.value,
                    date: this.dateStart
                }).then(() => this.close({
                    time: this.time.value,
                    date: this.dateStart
                })).catch((error) => {
                    if (error && error.response && error.response.data) {
                        this.errors = error.response.data;
                    }
                });
            },
            cancel() {
                this.close();
            },
            close(time) {
                this.$emit('close', time ? time : this.initTime);
            },
            clearErrors() {
                this.errors = {}
            }
        }
    }
</script>