<template>
    <div 
        class="filter"
        :class="filterOpened ? 'is-open' : ''"
    >
        <div 
            class="filter__show"
            @click.stop="slide"
        >
            Фильтры
        </div>
        <div class="filter__inner">
            <div class="filter__title h1">
                Фильтры
            </div>
            <div 
                class="filter__close"
                @click.stop="slide"
            />
            <div class="filter__block">
                <div class="filter__field field">
                    <div class="field__title">
                        Номер тендера
                    </div>
                    <div class="field__input">
                        <input
                            v-model="filter.code"
                            type="text"
                            placeholder="00000000"
                        >
                    </div>
                </div>
                <div class="filter__field field">
                    <div class="field__title">
                        Начало торгов
                    </div>
                    <div class="field__input">
                        <date-input
                            v-model="filter.date_start"
                            name="date_start"
                        />
                    </div>
                </div>
                <div class="filter__field field">
                    <div class="field__title">
                        Дата выгрузки
                    </div>
                    <div class="field__input">
                        <date-input
                            v-model="filter.date_end"
                            name="date_start"
                        />
                    </div>
                </div>
                <div class="filter__field field">
                    <div class="field__title">
                        Тип выгрузки
                    </div>
                    <multiSelect
                        v-model="filter.tasks__vehicle"
                        :options="unloadingTypes"
                        :searchable="true"
                        track-by="name"
                        label="name"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder=""
                    />
                </div>
                <div class="filter__field field">
                    <div class="field__title">
                        Тип тендера
                    </div>
                    <multiSelect
                        v-model="filter.t_type"
                        :options="tenderTypes"
                        :searchable="true"
                        track-by="name"
                        label="name"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder=""
                    />
                </div>
                <div class="filter__field field">
                    <div class="field__title">
                        Тип ТС
                    </div>
                    <multiSelect
                        v-model="filter.tasks__ts_type"
                        :options="tsTypes"
                        :searchable="true"
                        track-by="name"
                        label="name"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder=""
                    />
                </div>
                <div class="filter__field field m--height-auto">
                    <div class="field__title">
                        Пункт назначения
                    </div>
                    <div class="field__city">
                        <CityTreeSelect
                            ref="city"
                            :limit="1"
                        />
                    </div>
                </div>
                <div class="filter__field field">
                    <div class="field__title">
                        Статус
                    </div>
                    <multiSelect
                        v-model="filter.status"
                        :options="statuses"
                        track-by="name"
                        label="name"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder=""
                    />
                </div>
            </div>
            <div class="filter__buttons">
                <button
                    class="filter__button button button-red m--search"
                    @click="doFilter"
                >
                    Поиск
                </button>

                <button
                    class="filter__button button m--add"
                    @click="addFilter"
                >
                    Создать фильтр
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import CityTreeSelect from "./CityTreeSelect";
    import AddFilterModal from "./AddFilterModal";
    import dateInput from '@/components/inputs/date-input'

    export default {
        name: "FilterPanel",
        components: {
            CityTreeSelect,
            dateInput
        },
        data() {
            return {
                // unloadingTypes: ["Любая", "Боковая", "Верхняя", "Задняя"],
                unloadingTypes: [
                    { name: "Любая", value: "other" },
                    { name: "Боковая", value: "side" },
                    { name: "Верхняя", value: "top" },
                    { name: "Задняя", value: "back" }
                ],
                // tenderTypes: ["Открытый", "Сервисный"],
                tenderTypes: [
                    { name: "Открытый", value: "0" },
                    { name: "Сервисный", value: "1" }
                ],
                // statuses: ["Новый", "Играют", "Я участвую", "Победа, есть документы", "Победа, нет документов"],
                statuses: [
                    { name: "Новый", value: "new" },
                    { name: "Играют", value: "played" },
                    { name: "Я участвую", value: "playedme" },
                    { name: "Победа, есть документы", value: "wondoc" },
                    { name: "Победа, нет документов", value: "wonnodoc" },
                ],
                // tsTypes: ["Тент", "Борт"]
                tsTypes: [
                    { name: "Тент", value: "0" },
                    { name: "Борт", value: "1" }
                ],
                filter: {},
                filterOpened: false
            }
        },
        computed: {
            destinations: function () {
                return this.$refs.city.value ? this.$refs.city.value : []
            }
        },
        methods: {
            buildFilter(){
                const actualFilter = Object.assign({}, this.filter);
                actualFilter.tasks__vehicle = this.filter.tasks__vehicle && this.filter.tasks__vehicle.value;
                actualFilter.t_type = this.filter.t_type && this.filter.t_type.value !== undefined ? this.filter.t_type.value : '';
                actualFilter.status = this.filter.status && this.filter.status.value;
                actualFilter.tasks__ts_type = this.filter.tasks__ts_type && this.filter.tasks__ts_type.value !== undefined ? this.filter.tasks__ts_type.value : '';
                actualFilter.destinations = this.destinations.map(d=>d.label).join(',');
                return actualFilter;
            },
            doFilter() {
                const actualFilter = this.buildFilter();
                this.$parent.clearActiveFilterStyles();
                this.$parent.applyFilter(actualFilter);
                this.filterOpened = false;
            },
            addFilter() {
                this.$modal.show(AddFilterModal, {
                    filter: this.buildFilter(),
                }, {
                    adaptive: true,
                    //scrollable: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                    //maxHeight: '90%'
                }, {
                    'closed': () => this.$parent.fetchFilters()
                });
            },
            slide() {
                this.filterOpened = !this.filterOpened;
            }

        },
    }
</script>
