<template>
    <div class="modal">
        <div class="modal__header h1">
            Сохранить новый фильтр
        </div>
        <form
            class="modal__body modal__form"
            @submit="save"
        >
            <div class="field m--full">
                <label class="field__title">Название нового фильтра</label>
                <div class="field__input">
                    <input
                        v-model="filter_name"
                        type="text"
                        placeholder="Введите название"
                    >
                </div>
            </div>
        </form>
        <div class="modal__footer m--flex">
            <button
                type="submit"
                class="button button-red"
                @click="save"
            >
                Сохранить
            </button>
            <button
                class="button button-transparent"
                @click="cancel"
            >
                Отменить
            </button>
        </div>
    </div>
</template>

<script>
    import { users } from "@/services"

    export default {
        name: "AddFilterModal",
        props: {
            filter: {
                type: Object,
                default() { return {} }
            }
        },
        data() {
            return {
                filter_name: '',
            }
        },
        methods: {
            cancel() {
                this.close();
            },
            close() {
                this.$emit('close');
            },
            save() {
                const queryParameters = Object.keys(this.filter)
                    .filter(key => this.filter[key])
                    .map(key => `${key}=${this.filter[key]}`)
                    .join("&");

                users.addFilter(queryParameters, this.filter_name)
                    .then(() => this.close());
            }
        }
    }
</script>