<template>
    <div class="vendor">
        <div class="vendor__header h1">
            {{ companyName }}
        </div>
        <VendorMenu
            :id="id"
            :name="companyName"
        />
        <div class="vendor__buttons">
            <button
                class="button button-red"
                @click="addDoc"
            >
                <span class="m--plus">+</span> Добавить документ
            </button>
        </div>
        <div
            v-if="documents && documents.length"
            class="vendor__docs-table table"
        >
            <div class="vendor__docs-table-header table__header">
                <div class="table__header-cell">
                    Номер договора
                </div>
                <div class="table__header-cell">
                    Дата заключения
                </div>
                <div class="table__header-cell">
                    Скан договора
                </div>
                <div class="table__header-cell">
                    Ваш комментарий
                </div>
                <div class="table__header-cell">
                    Статус
                </div>
                <div class="table__header-cell" />
            </div>
            <VendorDocsItem
                v-for="doc in documents"
                :key="doc.id"
                :doc="doc"
                @editDoc="editDoc"
                @setDocStatus="setDocStatus"
            />
        </div>
        <div
            v-else
            class="account__card"
        >
            Документы не добавлены
        </div>
    </div>
</template>

<script>
    import VendorMenu from "@/components/VendorMenu";
    import VendorDocsEditModal from "@/components/VendorDocsEditModal";
    import VendorDocsItem from "@/components/VendorDocsItem";
    import { users } from "@/services";

    export default {
        name: "AccountDocs",
        components: {
            VendorMenu,
            VendorDocsItem
        },
        props: {
            name: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                documents: [],
                id: Number.parseInt(this.$route.params.id),
                companyName: ''
            }
        },
        beforeMount() {
            this.getDocuments(this.id);
            if (this.name) {
                this.companyName = this.name;
            } else {
                this.getAccountInfo(this.id)
            }
        },
        methods: {
            getAccountInfo(id) {
                users.getProfile(id).then((res) => {
                    this.companyName = res.company.company_name
                }).catch((error) => {
                    console.log(error);
                })
            },
            getDocuments(id) {
                users.getVendorDocuments(id)
                    .then(response => this.documents = response);
            },
            addDoc() {
                this.$modal.show(VendorDocsEditModal, {
                    id: this.id,
                    mode: 'create',
                    isManager: true
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getDocuments(this.id)
                });
            },
            editDoc(doc) {
                this.$modal.show(VendorDocsEditModal, {
                    mode: 'edit',
                    initDoc: doc,
                    id: this.id,
                    isManager: true
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getDocuments(this.id)
                });
            },
            setDocStatus(docId, status) {
                users.setDocActive(docId, status)
                    .then(() => {
                        this.getDocuments(this.id);
                    });
            },
        }
    }
</script>
