export default function(value, a, b, c) {
    const d100 = value % 100,
        d10 = value % 10;
    if (d100 < 5 || d100 > 20) {
        if (d10 == 1) {
            return a;
        } else  if (d10 > 0 && d10 < 5) {
            return b;
        }
    }
    return c;
}
