<template>
    <div
        v-if="profile"
        class="vendor"
    >
        <div class="vendor__header h1">
            {{ companyName }}
        </div>
        <VendorMenu
            :id="id"
            :name="companyName"
        />
        <div class="vendor__card">
            <div class="vendor__card-title h2">
                Информация о компании
            </div>
            <div class="vendor__card-form">
                <div class="field m--small">
                    <div class="field__title">
                        Юридический адрес
                    </div>
                    <div class="field__input">
                        <input
                            id="company_addr"
                            type="text"
                            :value="profile.company.company_addr"
                        >
                    </div>
                </div>
                <div class="field m--small">
                    <div class="field__title">
                        Почтовый адрес
                    </div>
                    <div class="field__input">
                        <input
                            id="company_postaddr"
                            type="text"
                            :value="profile.company.company_post_addr"
                        >
                    </div>
                </div>
                <div class="field m--small">
                    <div class="field__title">
                        ИНН
                    </div>
                    <div class="field__input">
                        <input
                            id="company_inn"
                            type="text"
                            :value="profile.company.company_inn"
                        >
                    </div>
                </div>
                <div class="field m--small">
                    <div class="field__title">
                        Телефон/Факс
                    </div>
                    <div class="field__input">
                        <input
                            id="company_phone"
                            type="text"
                            :value="profile.company.company_phone"
                        >
                    </div>
                </div>
                <div class="field m--small">
                    <div class="field__title">
                        ФИО директора
                    </div>
                    <div class="field__input">
                        <input
                            id="company_seo"
                            type="text"
                            :value="profile.company.company_seo"
                        >
                    </div>
                </div>
                <div class="field m--small">
                    <div class="field__title">
                        Веб-сайт
                    </div>
                    <div class="field__input">
                        <input
                            id="company_site"
                            type="text"
                            :value="profile.company.company_site"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="vendor__card">
            <div class="vendor__card-title h2">
                Контактная информация
            </div>
            <div class="vendor__card-form">
                <div class="field m--small">
                    <div class="field__title">
                        ФИО
                    </div>
                    <div class="field__input">
                        <input
                            id="profile_name"
                            type="text"
                            :value="profile.name"
                        >
                    </div>
                </div>
                <div class="field m--small">
                    <div class="field__title">
                        Email
                    </div>
                    <div class="field__input">
                        <input
                            id="profile_email"
                            type="text"
                            :value="profile.email"
                        >
                    </div>
                </div>
                <div class="field m--small">
                    <div class="field__title">
                        Телефон/ Факс
                    </div>
                    <div class="field__input">
                        <input
                            id="profile_phone"
                            type="text"
                            :value="profile.phone"
                        >
                    </div>
                </div>
                <div class="field m--small">
                    <div class="field__title">
                        Дополнительный телефон
                    </div>
                    <div class="field__input">
                        <input
                            id="profile_ephone"
                            type="text"
                            :value="profile.extraphone"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import VendorMenu from "@/components/VendorMenu";
    import { users } from "@/services";

    export default {
        name: "Vendor",
        components: {
            VendorMenu,
        },
        props: {
            name: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
                profile: undefined,
                companyName: 'Поставщик'
            }
        },
        beforeMount() {
            this.getAccountInfo(this.id)
        },
        methods: {
            getAccountInfo(id) {
                users.getProfile(id).then((res) => {
                    this.profile = res;
                    this.companyName = res.company.company_name
                }).catch((error) => {
                    console.log(error);
                })
            },
        }
    }
</script>
