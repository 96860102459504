import Vue from 'vue'

import App from './App.vue'
import router from './router/router';
import store from './store/store';

import VModal from 'vue-js-modal'
import multiSelect from 'vue-multiselect'
import filters from './utils/filters';

Vue.config.productionTip = false;
Vue.use(VModal);
Vue.component('multiSelect', multiSelect);

for (let filter in filters) {
    Vue.filter(filter, filters[filter]);
}

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
})
