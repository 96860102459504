<template>
    <div class="modal">
        <div class="modal__header h1">
            {{ mode === 'create' ? 'Добавить новую транспортную компанию' : 'Редактировать транспортную компанию' }}
        </div>
        <form
            id="modal_form"
            class="modal__body modal__form"
            @submit.prevent="save"
        >
            <div v-if="mode==='create'">
                <div class="field__row">
                    <field
                        v-model="vendor.user.username"
                        name="username"
                        label="Логин пользователя (лат.)"
                        placeholder="Введите логин"
                        :small="true"
                        :required="true"
                        :errors="errors.user ? errors.user.username : undefined"
                    />
                    <field
                        v-model="vendor.user.password"
                        name="password"
                        label="Пароль для входа в систему"
                        placeholder="Введите пароль"
                        type="password"
                        :small="true"
                        :required="true"
                        :errors="errors.user ? errors.user.password : undefined"
                    />
                </div>
                <field
                    v-model="vendor.name"
                    name="name"
                    label="ФИО"
                    placeholder="Введите ФИО"
                    :small="true"
                    :full="true"
                    :required="true"
                    :errors="errors.name"
                />
                <div class="field__row">
                    <field
                        v-model="vendor.email"
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="Введите email"
                        :small="true"
                        :required="true"
                        :errors="errors.email"
                    />
                    <field
                        v-model="vendor.phone"
                        name="phone"
                        type="phone"
                        label="Телефон"
                        placeholder="+7 (999) 999-9999"
                        :small="true"
                        :required="true"
                        :errors="errors.phone"
                    />
                </div>
            </div>
            <div class="field__row">
                <field
                    v-model="vendor.company.company_name"
                    name="company_name"
                    type="text"
                    label="Название компании"
                    placeholder="Введите название компании"
                    :small="true"
                    :required="true"
                    :errors="errors.company.company_name"
                />
                <field
                    v-model="vendor.company.company_seo"
                    name="company_seo"
                    type="text"
                    label="ФИО директора"
                    placeholder="Введите ФИО директора"
                    :small="true"
                    :errors="errors.company.company_seo"
                />
            </div>
            <field
                v-model="vendor.company.company_addr"
                name="company_addr"
                type="text"
                label="Юридический адрес"
                placeholder="Введите адрес компании"
                :small="true"
                :full="true"
                :errors="errors.company.company_addr"
            />
            <field
                v-model="vendor.company.company_post_addr"
                name="company_post_addr"
                type="text"
                label="Почтовый адрес"
                placeholder="Введите адрес компании"
                :small="true"
                :full="true"
                :errors="errors.company.company_post_addr"
            />
            <field
                v-model="vendor.company.company_inn"
                name="company_inn"
                type="mask"
                label="ИНН"
                mask="9{10,12}"
                placeholder="Введите ИНН компании"
                :small="true"
                :full="true"
                :errors="errors.company.company_inn"
            />
            <div class="field__row">
                <field
                    v-model="vendor.company.company_site"
                    name="company_site"
                    label="Веб-сайт"
                    placeholder="Введите ссылку на сайт"
                    :small="true"
                    :errors="errors.company.company_site"
                />
                <field
                    v-model="vendor.company.company_phone"
                    name="company_phone"
                    type="phone"
                    label="Телефон/Факс"
                    placeholder="+7 (999) 999-9999"
                    :required="true"
                    :small="true"
                    :errors="errors.company.company_phone"
                />
            </div>
        </form>

        <div class="modal__footer m--flex">
            <button
                class="button button-red"
                type="submit"
                form="modal_form"
            >
                Сохранить
            </button>
            <button
                class="button button-transparent"
                @click="cancel"
            >
                Отменить
            </button>
        </div>
    </div>
</template>

<script>
    import { users } from '@/services';
    import field from '@/components/field.vue';

    export default {
        name: "VendorEditModal",
        components: {
            field
        },
        props: {
            mode: {
                type: String,
                default: ''
            },
            initVendor: {
                type: Object,
                default() { return {
                    'user': {
                        'username': '',
                        'password': ''
                    },
                    'name': '',
                    'phone': '',
                    'email': '',
                    'company': {
                        'company_phone': '',
                        'company_seo': '',
                        'company_name': '',
                        'company_site': '',
                        'company_inn': '',
                        'company_post_addr': '',
                        'company_addr': ''
                    }
                }; }
            }
        },
        data() {
            return {
                vendor: this.initVendor,
                errors: {
                    'user': {},
                    'company': {}
                }
            }
        },
        methods: {
            cancel() {
                this.close();
            },
            close() {
                this.$emit('close');
            },
            save() {
                this.checkFields();
                for (let error in this.errors) {
                    if (this.errors[error] && Object.keys(this.errors[error]).length !== 0) {
                        return;
                    }
                }
                switch (this.mode) {
                case 'create':
                    users.addVendor(this.vendor)
                        .then(() => this.close())
                        .catch(error => {
                            if (error && error.response && error.response.data) {
                                this.clearErrors();
                                this.errors = Object.assign({}, this.errors, error.response.data);
                            }
                        });
                    break;
                case 'edit':
                    users.updateVendor(this.vendor)
                        .then(() => this.close())
                        .catch(error => {
                            if (error && error.response && error.response.data) {
                                this.errors = error.response.data;
                            }
                        });
                    break;
                }
            },
            checkFields() {
                this.clearErrors();
                let emptyError = ['Это поле не может быть пустым']
                for (let item in this.vendor) {
                    if (item === 'company') {
                        if (!this.vendor[item].company_name) {
                            this.errors[item].company_name = emptyError;
                        }
                        if (!this.vendor[item].company_phone) {
                            this.errors[item].company_phone = emptyError;
                        }
                    } else if (item === 'user') {
                        if (!this.vendor[item].username) {
                            this.errors[item].username = emptyError;
                        }
                        if (!this.vendor[item].password) {
                            this.errors[item].password = emptyError;
                        }
                    } else if (item === 'name') {
                        if (!this.vendor[item]) {
                            this.errors[item] = emptyError;
                        }
                    } else if (item === 'phone') {
                        if (!this.vendor[item]) {
                            this.errors[item] = emptyError;
                        }
                    } else if (item === 'email') {
                        if (!this.vendor[item]) {
                            this.errors[item] = emptyError;
                        }
                    }
                }
            },
            clearErrors() {
                this.errors = {
                    'user': {},
                    'company': {}
                }
            }
        }
    }
</script>