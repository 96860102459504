<template>
    <date-pick
        v-model="val"
        :format="format"
        :weekdays="weekdays"
        :months="months"
        placeholder="dd.mm.yyyy"
        class="input"
    />
</template>

<script>
    import { parseDate, formatDate } from '@/utils/helpers';
    import DatePick from 'vue-date-pick';
    import 'vue-date-pick/dist/vueDatePick.css';

    export default {
        components: {
            DatePick
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: [String, Number],
                default: ''
            },
            format: {
                type: String,
                default: 'DD.MM.YYYY'
            }
        },
        data() {
            return {
                val: this.encode(this.value),
                weekdays: [
                    'Пон', 'Вт', 'Ср', 'Чт', 'Пят', 'Суб', 'Вос'
                ],
                months: [
                    'Январь', 'Февраль', 'Март', 'Апрель',
                    'Май', 'Июнь', 'Июль', 'Август',
                    'Сентябрь', 'Октрябрь', 'Ноябрь', 'Декабрь'
                ]
            };
        },
        watch: {
            val: function (value) {
                this.val = this.mask(value);
                if (value.length === 10 || value.length === 0) {
                    this.change(value);
                }
            }
        },
        methods: {
            change(value) {
                this.$emit('change', this.decode(value));
            },
            encode(value) {
                if (!value) {
                    return '';
                }
                if (/(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})/g.test(value)) {
                    return value;
                }
                return formatDate(parseDate(value, 'YYYY-MM-DD'), 'DD.MM.YYYY');
            },
            decode(value) {
                if (!value) {
                    return '';
                }

                return formatDate(parseDate(value, 'DD.MM.YYYY'), 'YYYY-MM-DD');
            },
            mask(value) {
                let v = value.replace(/\D/g,'').slice(0, 10);

                if (v.length >= 5) {
                    return `${v.slice(0,2)}.${v.slice(2,4)}.${v.slice(4)}`;
                }
                else if (v.length >= 3) {
                    return `${v.slice(0,2)}.${v.slice(2)}`;
                }
                return v
            }
        }
    };
</script>
