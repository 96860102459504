<template>
    <div class="reports">
        <form
            class="reports__form"
            @submit.prevent="createReport()"
        >
            <field
                v-model="start_date"
                type="date"
                name="start_date"
                label="Дата начала отчета"
                placeholder="mm.dd.yyyy"
                :small="true"
                :full="true"
                :required="true"
                :errors="errors.start_date"
            />
            <field
                v-model="end_date"
                type="date"
                name="end_date"
                label="Дата конца отчета"
                placeholder="mm.dd.yyyy"
                :full="true"
                :small="true"
                :required="true"
                :errors="errors.end_date"
            />
            <button
                class="reports__form-submit button button-red"
                @submit="createReport()"
            >
                <div class="make-report" />
                Сформировать отчет
            </button>
        </form>
        <div
            v-if="reports.length > 0"
            class="reports__items"
        >
            <div class="reports__items-header">
                <div class="reports__report-field">
                    Дата формирования отчета
                </div>
                <div class="reports__report-field">
                    Период отчета
                </div>
                <div class="reports__report-field link">
                    Ссылка
                </div>
            </div>
            <div
                v-for="report in reports"
                :key="report.id"
                class="reports__report"
            >
                <div class="reports__report-field">
                    {{ report.created_date }}
                </div>
                <div class="reports__report-field">
                    с {{ report.start_date }} по {{ report.end_date }}
                </div>
                <div class="reports__report-field link">
                    <a
                        class="reports__report-link red-link"
                        :href="get_link(report)"
                        download
                    >
                        Скачать отчет
                    </a>
                </div>
            </div>
        </div>
        <div
            v-else
            class="reports__items"
        >
            <div class="reports__items-empty">
                Здесь пока пусто.. Сформируйте свой первый отчет
            </div>
        </div>
    </div>
</template>

<script>
    import { users as api } from '@/services';
    import { users as settings } from '@/settings';
    import Field from "../components/field";

    export default {
        name: "Reports",
        components: {
            Field
        },
        data() {
            return {
                start_date: '',
                end_date: '',
                reports: [],
                errors: {},
            }
        },
        mounted() {
            this.clearErrors();
            this.getReports();
        },
        methods: {
            createReport() {
                this.clearErrors();
                return api.createReport(this.start_date, this.end_date).then(() => {
                    this.getReports()
                }).catch((error) => {
                    if (error && error.response && error.response.data) {
                        this.errors = error.response.data;
                    }
                })
            },
            getReports() {
                api.getReports().then(response => this.reports = response);
            },
            get_link(report) {
                return `${settings.report}/${report.id}`;
            },
            clearErrors() {
                this.errors = {}
            }
        }
    }
</script>