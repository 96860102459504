<template>
    <div class="navBar">
        <div class="navBar__header">
            <router-link
                :to="{ name: 'tenders' }"
                class="navBar__header-logo"
            />
            <div class="navBar__header-container container header">
                <div class="header__block">
                    <div class="header__text">
                        По вопросам работы
                        площадки звоните:
                    </div>
                    <a
                        :href="`tel:${phone}`"
                        class="header__phone button"
                    >
                        {{ phone }}
                    </a>
                </div>
                <div class="header__block m--mobile-hidden">
                    <div class="header__username">
                        {{ username }}
                    </div>
                    <template
                        v-for="nav in navigation.filter((item) => (item.name === 'chat' || item.name === 'notifications')).reverse()"
                    >
                        <router-link
                            :key="nav.name"
                            class="header__icon"
                            :class="['m--' + nav.icon, { 'has-msg': nav.name === 'chat' && roomUnreadCount > 0 }, { 'alarm': nav.name === 'notifications' && hasNotification }]"
                            :to="{ name: nav.name }"
                            :title="nav.title"
                            @click="close()"
                        >
                            <div
                                v-if="nav.name === 'chat' && roomUnreadCount > 0"
                                class="header__icon-alert"
                            >
                                {{ roomUnreadCount }}
                            </div>
                        </router-link>
                    </template>
                    <div
                        class="button button-gray header__logout"
                        @click="logout"
                    >
                        Выход
                    </div>
                </div>
                <div class="header__block m--mobile-show">
                    <div
                        class="header__burger"
                        :class="{'alert': (roomUnreadCount > 0 || hasNotification )}"
                        @click="open"
                    />
                </div>
            </div>
        </div>
        <div
            class="navBar__menu-outer"
            :class="navBarOpened ? 'is-open' : ''"
            @click="close()"
        />
        <div
            v-if="(navigation.length > 0)"
            class="navBar__menu"
            :class="navBarOpened ? 'is-open' : ''"
        >
            <div class="navBar__username">
                {{ username }}
            </div>
            <div class="navBar__menu-inner">
                <template
                    v-for="nav in navigation"
                >
                    <router-link
                        v-if="checkRole(nav)"
                        :key="nav.name"
                        class="navBar__menu-item"
                        :class="['m--' + nav.icon, { 'has-msg': nav.name === 'chat' && roomUnreadCount > 0 }, { 'alarm': nav.name === 'notifications' && hasNotification }]"
                        :to="{ name: nav.name }"
                        :title="nav.title"
                        @click="close()"
                    >
                        {{ nav.title }}
                        <div
                            v-if="nav.name === 'chat' && roomUnreadCount > 0"
                            class="navBar__menu-alert"
                        >
                            {{ roomUnreadCount }}
                        </div>
                    </router-link>
                </template>
                <div
                    class="button button-gray navBar__menu-logout"
                    @click="logout"
                >
                    Выход
                </div>
            </div>
            <div 
                class="navBar__menu-close"
                @click="close"
            />
        </div>
    </div>
</template>


<script>
    import { navBar } from '../settings';
    import { push as Chat, chat } from '@/services';

    export default {
        data() {
            return {
                username: this.$store.state.username,
                navigation: [],
                navBarOpened: false,
                phone: '8 800 250 30 10',
                chat: undefined,
                isConnected: false,
                hasNotification: false,
                roomUnreadCount: 0
            }
        },
        watch: {
            '$route' (value) {
                this.getCount()
                if (this.hasNotification && value.name === 'notifications') {
                    this.hasNotification = false;
                }
                this.close();
            }
        },
        created() {
            this.navigation = navBar;
        },
        mounted() {
            this.connectToPushWS()
        },
        destroyed() {
            this.chat.closeChat();
        },
        methods: {
            logout() {
                this.$store.commit('removeToken');
                this.$router.push({ name: 'login' });
            },
            open() {
                this.navBarOpened = true;
            },
            close() {
                this.navBarOpened = false;
            },
            checkRole(nav) {
                return nav.role === 'all' || (nav.role === 'manager' && this.$store.state.role);
            },
            connectToPushWS(){
                console.log("Starting connection to WebSocket Server")
                this.chat = new Chat();
                this.chat.onEvent('open', (res) => {
                    console.log('Push is active', res);
                    this.isConnected = true;
                    // this.getCount()
                });
                this.chat.onEvent('close', (isOK, e) => {
                    if (isOK) {
                        console.log('Chat is closed');
                    } else {
                        console.warn(`Chat is closed with code ${e.code}: ${e.reason}`, e);
                    }
                    this.isConnected = false;
                });
                this.chat.onEvent('error', (e) => {
                    console.error('Chat has received an error', e);
                });
                this.chat.onEvent('message', (data) => {
                    console.log('Chat has received a message', data);
                    this.handlePush(data);
                });

                this.chat.openChat();
            },
            handlePush(event) {
                let route_path = this.$route.path
                switch(event.push_reason) {
                case 'notification':
                    if (!route_path.includes('notification')) {
                        this.hasNotification = true;
                    }
                    break;
                case 'chat_update':
                    this.roomUnreadCount = event.unread_room_count
                    break;
                }
            },
            getCount() {
                chat.getUnreadCount().then(res => {
                    this.roomUnreadCount = res.unread_room_count
                }).catch(err => {
                    console.error(err);
                });
            }
        },
    }
</script>