<template>
    <!-- valueConsistsOf: контролирует "схлопывание" выбранных элементов в группы.
    Для наглядности можно поставить ALL и посмотреть, что будет с лейблами и value -->
    <treeSelect
        v-model="value"
        :multiple="true"
        :searchable="true"
        :always-open="alwaysOpen"
        :limit="limit || Infinity"
        :limit-text="(count) => `и ещё ${count}`"
        placeholder="Введите название города"
        value-format="object"
        :options="treeData"
    />
</template>

<script>
    import treeSelect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import "@/assets/css/lib/city-tree.css"
    import { country } from "@/services";

    export default {
        name: 'CityTreeSelect',
        components: {
            treeSelect
        },
        props: {
            alwaysOpen: {
                type: Boolean,
                default: false
            },
            initCityIds: {
                type: Array,
                default() { return [] }
            },
            limit: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                treeData: [],
                value: null,
                asyncBoolean: false,
                options: null
            }
        },
        beforeMount() {
            this.fetchCities();
        },
        methods: {
            fetchCities() {
                country.getCities()
                    .then(res => {
                        this.countries = res;
                        this.prepareTreeData();
                        this.initValue();
                    });
            },
            prepareTreeData() {
                this.treeData = this.countries.map(c => this.createCountryNode(c));
            },
            initValue() {
                const res = [];
                this.treeData
                    .map(n => this.addIfSelected(n, res));
                this.value = res;
            },
            addIfSelected(node, res) {
                if (this.isNodeSelected(node)) {
                    res.push(node);
                } else {
                    (node.children || []).forEach(c => this.addIfSelected(c, res))
                }
            },
            isNodeSelected(node) {
                if (node.type === 'city') {
                    return (this.initCityIds || []).includes(node.entity.id);
                }
                if (!node.children || !node.children.length) {
                    return false;
                }
                return node.children.every(c => this.isNodeSelected(c));
            },
            createCountryNode(country) {
                const id = 'C-' + country.id;
                const res = {
                    id,
                    entity: country,
                    type: 'country',
                    label: country.name,
                    children: (country.districts || [])
                        .map(d => this.createDistrictNode(d, id))
                        // .concat((country.regions || [])
                        //     .map(r => this.createRegionNode(r, id)))
                };
                if (!res.children.length) {
                    delete res.children;
                }
                return res;
            },
            createDistrictNode(district, idPrefix) {
                const id = idPrefix + '.d-' + district.id;
                const res = {
                    id,
                    entity: district,
                    type: 'district',
                    label: district.name,
                    children: (district.regions || [])
                        .map(r => this.createRegionNode(r, id))
                };
                if (!res.children.length) {
                    delete res.children;
                }
                return res;
            },
            createRegionNode(region, idPrefix) {
                const id = idPrefix + '.r-' + region.id;
                const res = {
                    id,
                    entity: region,
                    type: 'region',
                    label: region.name,
                    children: (region.cities || [])
                        .map(c => this.createCityNode(c, id))
                };
                if (!res.children.length) {
                    delete res.children;
                }
                return res;
            },
            createCityNode(city, idPrefix) {
                const id = idPrefix + '.c-' + city.id;
                return {
                    id,
                    entity: city,
                    type: 'city',
                    label: city.name
                };
            },
            getSelectedCities() {
                return (this.value || [])
                    .flatMap(n => this.getCityNodesRecursive(n))
                    .map(n => n.entity);
            },
            getCityNodes() {
                return this.treeData.flatMap(n => this.getCityNodesRecursive(n));
            },
            getCityNodesRecursive(node) {
                return node.type === 'city'
                    ? [node]
                    : (node.children || []).flatMap(n => this.getCityNodesRecursive(n));
            }
        }
    }
</script>