import { parseDate, formatDate, parseLocalDate } from '@/utils/helpers';

export default function(value, template='DD.MM.YYYY', local=false) {
    if (!value) {
        return '';
    }

    if (local) {
        if (/^[0-9-]+$/.test(value)) {
            return formatDate(parseLocalDate(value, 'YYYY-MM-DD'), template);
        }
        return formatDate(parseLocalDate(value, 'isoDateTime'), template);
    }

    if (/^[0-9-]+$/.test(value)) {
        return formatDate(parseDate(value, 'YYYY-MM-DD'), template);
    }
    return formatDate(parseDate(value + '+07:00', 'isoDateTime'), template);
    // return formatDate(parseDate(value, 'YYYY-MM-DD HH:mm:ss'), template);
}
