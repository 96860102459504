<template>
    <div class="vendor">
        <div class="vendor__header h1">
            {{ companyName }}
        </div>
        <VendorMenu
            :id="id"
            :name="companyName"
        />
        <div
            v-if="cars && cars.length"
            class="vendor__cars-table table"
        >
            <div class="vendor__cars-table-header table__header">
                <div class="table__header-cell">
                    Марка/ Модель
                </div>
                <div class="table__header-cell">
                    Гос. номер
                </div>
                <div class="table__header-cell">
                    Номер прицепа
                </div>
                <div class="table__header-cell">
                    Грузо&shy;подъ&shy;емность, т
                </div>
                <div class="table__header-cell">
                    Тип вы&shy;грузки
                </div>
                <div class="table__header-cell">
                    Тип ТС
                </div>
                <div class="table__header-cell">
                    Ваш ком&shy;мен&shy;тарий
                </div>
                <div class="table__header-cell" />
            </div>
            <VendorCarsItem
                v-for="car in cars"
                :key="car.id"
                :car="car"
                @editCar="editCar"
                @setCarStatus="setCarStatus"
            />
        </div>
        <div
            v-else
            class="account__card"
        >
            Поставщик не предоставил информацию о машинах
        </div>
    </div>
</template>

<script>
    import VendorMenu from "@/components/VendorMenu";
    import VendorCarsItem from "@/components/VendorCarsItem";
    import AddCarModal from "@/components/AddCarModal";
    import { users } from "@/services";

    export default {
        name: "AccountCars",
        components: {
            VendorMenu,
            VendorCarsItem
        },
        props: {
            name: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                cars: [],
                id: Number.parseInt(this.$route.params.id),
                companyName: ''
            }
        },
        beforeMount() {
            this.getCars(this.id);
            if (this.name) {
                this.companyName = this.name;
            } else {
                this.getAccountInfo(this.id)
            }
        },
        methods: {
            getAccountInfo(id) {
                users.getProfile(id).then((res) => {
                    this.companyName = res.company.company_name
                }).catch((error) => {
                    console.log(error);
                })
            },
            getCars(id) {
                users.getVendorCars(id)
                    .then(response => this.cars = response);
            },
            editCar(car) {
                this.$modal.show(AddCarModal, {
                    mode: 'edit',
                    initCar: car,
                    statusPermission: true
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getCars(this.id)
                });
            },
            setCarStatus(carId, status) {
                users.setCarActive(carId, status)
                    .then(this.getCars(this.id));
            }
        }
    }
</script>
