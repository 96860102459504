<template>
    <div class="modal">
        <div class="modal__header h1">
            Добавить город
        </div>
        <div class="modal__body modal__form">
            <div class="field m--full">
                <div class="field__title">
                    Поиск города
                </div>
                <div class="field__input">
                    <CityTreeSelect
                        ref="citiesInput"
                        :always-open="alwaysOpen"
                        :init-city-ids="userCityIds"
                    />
                </div>
            </div>
        </div>
        <div class="modal__footer m--flex">
            <button
                class="button button-red"
                @click="save"
            >
                Сохранить
            </button>
            <button
                class="button button-transparent"
                @click="cancel"
            >
                Отменить
            </button>
        </div>
    </div>
</template>

<script>
    import CityTreeSelect from "./CityTreeSelect";
    import { users } from '@/services'

    export default {
        name: "AddCityModal",
        components: {CityTreeSelect},
        props: {
            alwaysOpen: {
                type: Boolean,
                default: true
            },
            userCityIds: {
                type: Array,
                default() { return [] }
            },
            onUpdate: {
                type: Function,
                default() {
                    return {};
                }
            }
        },
        data() {
            return {
                errors: {}
            }
        },
        methods: {
            cancel() {
                this.close();
            },
            close() {
                this.clearErrors()
                this.$emit('close');
            },
            save() {
                this.clearErrors()
                const cityIds = this.$refs.citiesInput.getSelectedCities()
                    .map(c => c.id);
                users.updateUserCities(cityIds)
                    .then(res => {
                        this.onUpdate && this.onUpdate(res);
                        this.close();
                    })
                    .catch((error) => {
                        if (error && error.response && error.response.data) {
                            this.errors = error.response.data;
                        }
                    });
            },
            clearErrors() {
                this.errors = {}
            }
        }
    }
</script>

<style scoped>
    .vm--modal {
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24), 0px 0px 46px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
    }
    .modal {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }
    .modal-b {
        padding: 32px 64px 0;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 115%;
        letter-spacing: 0.01em;
    }
    .modal-m {
        padding-left: 64px;
        padding-right: 64px;
        min-height: 450px;
    }
    .modal-m > .field {
        max-width: unset;
    }
    .modal-e {
        display: flex;
        justify-content: space-between;
        padding: 32px 64px;
    }
    .button {
        height: 48px;
    }
    .field {
        margin-top: 42px;
        color: unset;
    }
    .field-span > .field {
        margin-right: 64px;
    }
    .pseudo-field {
        margin-top: 42px;
    }
    textarea {
        height: 100px;
    }
</style>