<template>
    <div class="account">
        <AccountMenu />
        <div class="account__buttons">
            <button
                class="button button-red"
                @click="addCar"
            >
                <span class="m--plus">+</span> Добавить машину
            </button>
        </div>
        <div
            v-show="cars && cars.length"
            class="account__cars-table table"
        >
            <div class="account__cars-table-header table__header">
                <div class="table__header-cell">
                    Марка/ Модель
                </div>
                <div class="table__header-cell">
                    Гос. номер
                </div>
                <div class="table__header-cell">
                    Номер прицепа
                </div>
                <div class="table__header-cell">
                    Грузо&shy;подъ&shy;емность, т
                </div>
                <div class="table__header-cell">
                    Тип вы&shy;грузки
                </div>
                <div class="table__header-cell">
                    Тип ТС
                </div>
                <div class="table__header-cell">
                    Статус
                </div>
                <div class="table__header-cell">
                    Ваш ком&shy;мен&shy;тарий
                </div>
                <div class="table__header-cell" />
            </div>
            <AccountCarsItem
                v-for="car in cars"
                :key="car.id"
                :car="car"
                @editCar="editCar"
            />
        </div>
    </div>
</template>

<script>
    import AccountMenu from "@/components/AccountMenu";
    import AccountCarsItem from "@/components/AccountCarsItem";
    import AddCarModal from "@/components/AddCarModal";
    import { users } from "@/services";

    export default {
        name: "AccountCars",
        components :{
            AccountMenu,
            AccountCarsItem
        },
        data() {
            return {
                cars: []
            }
        },
        beforeMount() {
            this.getCars();
        },
        methods: {
            getCars() {
                users.getCars().then(response => this.cars = response);
            },
            addCar() {
                this.$modal.show(AddCarModal, {
                    mode: 'create'
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getCars()
                });
            },
            editCar(car) {
                this.$modal.show(AddCarModal, { 
                    mode: 'edit', 
                    initCar: car 
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getCars()
                });
            }
        }
    }
</script>
