<template>
    <div class="login">
        <form
            class="login__form"
            @submit.prevent="auth"
        >
            <div class="login__form-title">
                Добро пожаловать
                <h2 class="h2">
                    Авторизуйтесь в системе
                </h2>
            </div>
            <div
                id="login-input"
                class="login__form-field field"
                :class="loginError ? 'is-error' : ''"
            >
                <div class="field-title">
                    Логин
                </div>
                <div class="field-input">
                    <input
                        v-model="username"
                        name="username"
                        type="text"
                    >
                </div>
                <div
                    v-if="loginError"
                    class="field__error"
                >
                    <ul class="field__error-list">
                        {{ loginError }}
                    </ul>
                </div>
            </div>
            <div
                id="pwd-input"
                class="login__form-field field"
                :class="passwordError ? 'is-error' : ''"
            >
                <div class="field-title">
                    Пароль
                </div>
                <div class="field-input">
                    <input
                        v-model="password"
                        name="password"
                        type="password"
                    >
                </div>
                <div
                    v-if="passwordError"
                    class="field__error"
                >
                    <ul class="field__error-list">
                        {{ passwordError }}
                    </ul>
                </div>
            </div>

            <button
                class="login__form-submit button button-red"
                :disabled="!!isLoading"
                @submit.prevent="auth"
            >
                Войти
            </button>
        </form>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                password: '',
                username: '',
                isLoading: false
            }
        },
        computed: {
            errors() {
                return this.$store.state.error;
            },
            loginError() {
                return this.errors && this.errors.username ? this.errors.username[0] : '';
            },
            passwordError() {
                if (this.errors && this.errors.password) {
                    return this.errors && this.errors.password ? this.errors.password[0] : '';
                } else if (this.errors && this.errors.detail) {
                    return this.errors && this.errors.detail ? this.errors.detail : '';
                }
                return '';
            },
            token() {
                return this.$store.state.token
            }
        },
        watch: {
            'username': function () {
                this.clearErrors();
            },
            'password': function () {
                this.clearErrors();
            },
            'token': function () {
                this.redirect();
            }
        },
        created() {
            this.clearErrors();
            this.redirect();
        },
        methods:{
            auth() {
                this.isLoading = true;
                this.clearErrors();
                this.$store.dispatch({
                    type: 'obtainToken',
                    username: this.username,
                    password: this.password
                }).then(() => {
                    this.isLoading = false;
                });
            },
            clearErrors() {
                this.$store.commit('updateError', null);
            },
            redirect() {
                if (this.$store.state.token) {
                    this.$router.push({ name: 'tenders' });
                }
            }
        },
    }
</script>