import { parse, format } from 'fecha';

function headerFormattedDate(dateTime) {
    return (dateTime || new Date()).toLocaleString(['ru'],
        { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute:'2-digit'});
}

export function parseDate(value, template) {
    return parse(value, template);
}

export function parseLocalDate(value, template) {
    return parse(value, template);
}

export function formatDate(value, template) {
    return format(value, template);
}

export default {
    headerFormattedDate,
}