<template>
    <div
        v-show="show"
        class="pagination"
    >
        <div class="pagination__info">
            Отобрано по параметрам: {{ count }}
        </div>
        <div class="pagination__block">
            <div class="pagination__block-select">
                <span class="pagination__block-info">На страницу:</span>
                <multiSelect
                    v-model="tendersPerPage"
                    :options="tendersPerPageOptions"
                    :searchable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :allow-empty="false"
                    label="name"
                    placeholder=""
                    @input="pageCountChanged"
                />
            </div>
            <div class="pagination__buttons">
                <div 
                    class="pagination__buttons-link m--prev" 
                    :class="offset === 0 ? 'is-disabled' : ''" 
                    @click="decrement" 
                />
                <span
                    v-for="num in pageNumRange.slice(offset, offset + 5)"
                    :key="num"
                    class="pagination__buttons-link m--number"
                    :class="currentPageNum === num ? 'is-active' : ''"
                    @click="setPageNumInternal(num)"
                >
                    {{ num }}
                </span>
                <div
                    class="pagination__buttons-link m--next"
                    :class="pageNumRange[pageNumRange.length - 1] <= offset + 5 ? 'is-disabled' : ''"
                    @click="increment"
                />
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ToolBar",
        props: {
            page: {
                type: Number,
                default: 1
            },
            pgCount: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                show: false,
                count: 0,
                offset: 0,
                currentPageNum: 1,
                pageCount: 1,
                pageNumRange: [1],
                tendersPerPage: { value: 10, name: "10 тендеров" },
                tendersPerPageOptions: [
                    { value: 10, name: "10 тендеров" },
                    { value: 20, name: "20 тендеров" },
                    { value: 30, name: "30 тендеров" },
                    { value: 40, name: "40 тендеров" },
                ],
            }
        },
        watch: {
            pgCount: function (value) {
                this.setCount(value);
            },
            page: function (value) {
                this.setPageNum(value);
            }
        },
        created() {
            this.setCount(this.pgCount);
            this.setPageNum(this.page);
            this.show = true;
        },
        methods: {
            updatePageNumRange() {
                this.pageCount = Math.ceil(this.count / this.tendersPerPage.value);
                this.pageNumRange = Array.from({length: this.pageCount}, (_, i) => i + 1);
            },
            pageCountChanged() {
                this.setPageNumInternal()
            },
            setCount(count) {
                this.count = count;
                this.updatePageNumRange();
            },
            setPageNum(num) {
                this.currentPageNum = num;
            },
            setPageNumInternal(num) {
                this.updatePageNumRange()
                this.$parent.fetchTenders(num);
            },
            decrement() {
                if (this.offset > 0) {
                    this.offset--
                }
            },
            increment() {
                if (this.pageNumRange[this.pageNumRange.length - 1] > this.offset + 5) {
                    this.offset++
                }
            }
        }
    }
</script>
