<template>
    <div class="modal">
        <div class="modal__header h1">
            {{ mode === 'create' ? 'Добавить нового водителя' : 'Редактировать водителя' }}
        </div>
        <div class="modal__body modal__form">
            <field
                v-model="driver.name"
                name="name"
                label="Фамилия Имя Отчество"
                placeholder="Введите ФИО"
                :small="true"
                :full="true"
                :required="true"
                :errors="errors.name"
            />
            <div class="field__row">
                <field
                    v-model="driver.passport_serial"
                    name="passport_serial"
                    type="mask"
                    mask="9999"
                    label="Серия паспорта"
                    placeholder="Введите серию"
                    :small="true"
                    :required="true"
                    :errors="errors.passport_serial"
                />
                <field
                    v-model="driver.passport_number"
                    name="passport_number"
                    type="mask"
                    mask="999999"
                    label="Номер паспорта"
                    placeholder="Введите номер"
                    :small="true"
                    :required="true"
                    :errors="errors.passport_number"
                />
            </div>
            <field
                v-model="driver.passport_info"
                name="passport_info"
                label="Кем выдан"
                placeholder="Введите орган выдачи"
                :full="true"
                :small="true"
                :required="true"
                :errors="errors.passport_info"
            />
            <div class="field__row">
                <field
                    v-model="driver.license"
                    name="license"
                    type="mask"
                    mask="99 99 999999"
                    label="Водительское удостоверение"
                    placeholder="Введите номер удостоверения"
                    :small="true"
                    :required="true"
                    :errors="errors.license"
                />
                <field
                    v-model="driver.phone"
                    name="phone"
                    type="phone"
                    label="Телефон"
                    placeholder="Введите телефон"
                    :small="true"
                    :required="true"
                    :errors="errors.phone"
                />
            </div>
            <field
                v-model="driver.comment"
                type="textarea"
                name="comment"
                label="Комментарий"
                placeholder="Введите комментарий"
                :full="true"
                :readonly="statusPermission"
                :errors="errors.comment"
            />
            <field
                v-model="driver.manager_comment"
                type="textarea"
                name="manager_comment"
                label="Комментарий менеджера"
                placeholder="Введите комментарий"
                :full="true"
                :disabled="!statusPermission"
                :readonly="!statusPermission"
                :errors="errors.manager_comment"
            />
            <div
                v-if="statusPermission"
                class="field m--checkbox"
            >
                <input
                    id="publication"
                    v-model="driver.publication"
                    type="checkbox"
                >
                <label for="publication">Активен</label>
            </div>
        </div>
        <div class="modal__footer m--flex">
            <button
                class="button button-red"
                @click="save"
            >
                Сохранить
            </button>
            <button
                class="button button-transparent"
                @click="cancel"
            >
                Отменить
            </button>
        </div>
    </div>
</template>

<script>
    import { users } from '@/services';
    import field from "@/components/field";

    export default {
        name: "AddDriverModal",
        components: {
            field
        },
        props: {
            mode: {
                type: String,
                default: ''
            },
            initDriver: {
                type: Object,
                default() { return {} }
            },
            statusPermission: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                driver: {},
                passportSeries: '',
                passportNumber: '',
                passportIssuer: '',
                errors: {}
            }
        },
        created() {
            this.driver = this.initDriver || {}
        },
        methods: {
            cancel() {
                this.$emit('close', 'cancel');
            },
            close() {
                this.$emit('close');
            },
            save() {
                this.clearErrors();
                switch (this.mode) {
                case 'create':
                    users.addDriver(this.driver)
                        .then(() => this.close())
                        .catch((error) => {
                            if (error && error.response && error.response.data) {
                                this.errors = error.response.data;
                            }
                        });
                    break;
                case 'edit':
                    users.updateDriver(this.driver)
                        .then(() => this.close())
                        .catch((error) => {
                            if (error && error.response && error.response.data) {
                                this.errors = error.response.data;
                            }
                        });
                    break;
                }
            },
            clearErrors() {
                this.errors = {}
            }
        }
    }
</script>
