<template>
    <div class="account">
        <AccountMenu />
        <div class="account__buttons">
            <button
                class="button button-red"
                @click="addCity"
            >
                <span class="m--plus">+</span> Добавить город
            </button>
        </div>
        <div
            v-if="filteredRegions && filteredRegions.length"
            class="account__card"
        >
            <div
                v-for="region in filteredRegions"
                :key="region.id"
                class="region"
            >
                <div class="region-label">
                    {{ region.name }}
                </div>
                <div
                    v-for="city in region.cities
                        .filter(c => userCityIds.includes(c.id))"
                    :key="city.id"
                    class="city-label"
                >
                    <span class="city-name">
                        {{ city.name }}
                    </span>
                    <span
                        class="city-delete"
                        @click="deleteCity(city.id)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AccountMenu from "@/components/AccountMenu";
    import AddCityModal from "@/components/AddCityModal";
    import { users, country } from "@/services";

    export default {
        name: "AccountCities",
        components :{
            AccountMenu
        },
        data() {
            return {
                cities: [],
                countries: [],
                regions: [],
                userCityIds: [],
            }
        },
        computed: {
            getRegions() {
                return (this.countries || [])
                    .flatMap(c => (c.regions || [])
                        .concat(c.districts
                            .flatMap(d => d.regions || [])));
            },
            filteredRegions() {
                return this.getRegions
                    .filter(r => (r.cities || [])
                        .some(c => this.userCityIds.includes(c.id)));
            }
        },
        beforeMount() {
            this.GetCities();
        },
        methods: {
            GetCities() {
                country.getCities()
                    .then(response => {
                        this.countries = response;
                        this.regions = this.getRegions;
                        this.getUserCities();
                    });
            },
            getUserCities() {
                users.getUserCities()
                    .then(res => this.resetUserCities(res.city_notices));
            },
            resetUserCities(ids) {
                this.userCityIds = ids;
            },
            addCity() {
                this.$modal.show(AddCityModal, {
                    alwaysOpen: true,
                    userCityIds: this.userCityIds,
                    onUpdate: res => this.resetUserCities(res.city_notices)
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, { });
            },
            deleteCity(cityId) {
                users.deleteUserCity(cityId)
                    .then(() => {
                        const i = this.userCityIds.indexOf(cityId);
                        if (i >= 0) {
                            const copy = [...this.userCityIds]
                            copy.splice(i, 1);
                            this.resetUserCities(copy)
                        }
                    });
            },
        }
    }
</script>