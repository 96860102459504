<template>
    <div
        v-if="vendor.company"
        class="vendors__table-row table__row"
        :class="itemOpened ? 'is-open' : ''"
    >
        <div class="vendors__table-row-cell table__row-cell m--header m--mobile-show">
            <span 
                class="m--link"
                @click="gotoVendor(vendor.id)"
            >
                {{ vendor.company.company_name }}
            </span>
            <div 
                class="vendors__table-row-slide table__row-slide" 
                @click.stop="slide"
            />
        </div>

        <div class="vendors__table-row-cell table__row-cell">
            <div class="vendors__table-row-title table__row-title">
                Логин
            </div>
            {{ vendor.user.username }}
        </div>
        <div class="vendors__table-row-cell table__row-cell m--mobile-hide">
            <span 
                class="m--link"
                @click="gotoVendor(vendor.id)"
            >
                {{ vendor.company.company_name }}
            </span>
        </div>
        <div class="vendors__table-row-cell table__row-cell">
            <div class="vendors__table-row-title table__row-title">
                Контакты
            </div>
            {{ vendor.name }}<br>{{ vendor.email }}<br>{{ vendor.phone }}
        </div>
        <div class="vendors__table-row-cell table__row-cell m--center">
            <div class="vendors__table-row-title table__row-title">
                Города
            </div>
            {{ vendor.cities_count }}
        </div>
        <div class="vendors__table-row-cell table__row-cell m--center">
            <div class="vendors__table-row-title table__row-title">
                Машины
            </div>
            {{ vendor.car_count }}
        </div>
        <div class="vendors__table-row-cell table__row-cell m--center">
            <div class="vendors__table-row-title table__row-title">
                Водители
            </div>
            {{ vendor.driver_count }}
        </div>
        <div class="vendors__table-row-cell table__row-cell m--center">
            <div class="vendors__table-row-title table__row-title">
                Рейтинг
            </div>
            {{ vendor.company.raiting }}
        </div>
        <div class="vendors__table-row-cell table__row-cell m--center">
            <div class="vendors__table-row-title table__row-title">
                Сделки
            </div>
            {{ vendor.tenders_count }}
        </div>
        <div class="vendors__table-row-cell table__row-cell m--center">
            <div class="vendors__table-row-title table__row-title">
                Активность
            </div>
            {{ vendor.company.active ? 'Активен' : 'Блок' }}
        </div>
        <div class="vendors__table-row-cell table__row-cell m--flex m--nopadding">
            <div
                class="m--icon m--gear"
                @click.stop="$emit('editVendor', vendor)"
            />
            <div
                :class="vendor.company.active ? 'm--icon m--lock-open' : 'm--icon m--lock-closed'"
                @click.stop="$emit('setVendorStatus', vendor.id, !vendor.company.active)"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            vendor: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                itemOpened: false
            }
        },
        methods: {
            gotoVendor(id) {
                this.$router.push(`/vendors/${id}/info`)
            },
            slide() {
                this.itemOpened = !this.itemOpened;
            }
        }
    }
</script>
