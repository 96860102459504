<template>
    <div
        class="vendor__docs-table-row table__row"
        :class="itemOpened ? 'is-open' : ''"
    >
        <div class="vendor__docs-table-row-cell table__row-cell m--header m--mobile-show">
            Договор №{{ doc.num }}
            <div 
                class="vendor__table-row-slide table__row-slide" 
                @click.stop="slide"
            />
        </div>
        <div class="table__row-cell m--mobile-hide">
            <div class="vendor__docs-table-row-title table__row-title">
                Номер договора
            </div>
            №{{ doc.num }}
        </div>
        <div class="table__row-cell m--center">
            <div class="vendor__docs-table-row-title table__row-title">
                Дата заключения
            </div>
            {{ doc.sign_date | date('DD.MM.YYYY') }}
        </div>
        <div class="table__row-cell m--center">
            <div class="vendor__docs-table-row-title table__row-title">
                Скан договора
            </div>
            <a
                :href="doc.file_scan"
                target="_blank"
                class="m--link"
                @click.prevent="downloadFile(doc.file_scan)"
            >
                Скачать
            </a>
        </div>
        <div class="table__row-cell">
            <div class="vendor__docs-table-row-title table__row-title">
                Ваш комментарий
            </div>
            {{ doc.manager_comment }}
        </div>
        <div class="table__row-cell m--center">
            <div class="vendor__docs-table-row-title table__row-title">
                Статус
            </div>
            {{ doc.publication ? 'Активен' : 'Блок' }}
        </div>
        <div class="table__row-cell m--flex m--nopadding">
            <div
                class="m--icon m--gear"
                @click="$emit('editDoc', doc)"
            />
            <div 
                :class="doc.publication ? 'm--icon m--lock-open' : 'm--icon m--lock-closed'"
                @click.stop="$emit('setDocStatus', doc.id, !doc.publication)"
            />
        </div>
    </div>
</template>

<script>
    // import VendorDocsEditModal from "@/components/VendorDocsEditModal";

    export default {
        props: {
            doc: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                itemOpened: false
            }
        },
        methods: {
            slide() {
                this.itemOpened = !this.itemOpened;
            },
            fileName(url) {
                return url.substring(url.lastIndexOf("/") + 1);
            },
            downloadFile(item) {
                if (item) {
                    const url = window.URL.createObjectURL(new Blob([item]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', this.fileName(item)) //or any other extension
                    document.body.appendChild(link)
                    link.click()
                }
            }
        }
    }
</script>
