<template>
    <div
        v-if="tender"
        class="tender"
    >
        <div class="tender__header">
            <div class="tender__header-block">
                <div class="tender__header-name h2">
                    <span class="tender__header-code">
                        №{{ tender.code }}
                    </span>
                    {{ tender.name }}
                </div>
                <div
                    v-if="!$store.state.role"
                    class="tender__header-message button button-transparent"
                    @click="gotoChat"
                >
                    <div class="tender__header-message-icon" />
                    <div class="tender__header-message-label">
                        Написать сообщение
                    </div>
                </div>
            </div>
            <div class="tender__header-block">
                <div class="tender__header-labels">
                    <div
                        v-if="['новый','играет'].includes(tender.status)"
                        class="tender__header-status"
                    >
                        {{ tender.status && tender.status.capitalize() }}
                    </div>
                    <div class="tender__header-status">
                        {{ tender.t_type && tender.t_type.capitalize() }}
                    </div>
                    <div
                        v-if="!['новый','играет'].includes(tender.status) && tender.user_declined && tender.declined"
                        class="tender__header-status m--no-docs"
                    >
                        ТК отказалось.<br>{{ tender.user_declined.company.company_name }}
                    </div>
                    <div
                        v-else-if="!['новый','играет'].includes(tender.status) && tender.user_winner"
                        class="tender__header-status"
                        :class="tender.confirmed ? 'm--confirmed' : 'm--waiting'"
                    >
                        Сыграл.<br>{{ tender.confirmed ? 'Подтвержден' : 'Ожидает подтверждения' }}
                    </div>
                    <div v-if="isExpired && tender.status !== 'архив'">
                        <div
                            v-if="!tender.vendor_car && !tender.vendor_driver"
                            class="tender__header-status m--docs m--no-docs"
                        >
                            Документов нет
                        </div>
                        <div
                            v-if="tender.vendor_car && tender.vendor_driver"
                            class="tender__header-status m--docs m--yes-docs"
                        >
                            Документы получены
                        </div>
                    </div>
                </div>
                <div class="tender__header-auction">
                    <div
                        class="tender__header-timer timer"
                        :class="[timerClass, !isExpired && isActive ? 'has-timer' : '']"
                    >
                        <div class="timer__icon" />
                        <div class="timer__status">
                            {{ timerText }}
                        </div>
                        <div
                            v-if="!isExpired && isActive"
                            class="timer__counter"
                        >
                            {{ timerTime }}
                        </div>
                    </div>
                    <div class="tender__header-dates">
                        <div class="tender__header-dates-start">
                            <span class="tender__header-dates-title">Начало торгов:</span>
                            <span class="tender__header-dates-time gray-text">{{ tender.date_start | date('DD.MM.YYYY, HH:mm') }}</span>
                        </div>
                        <div class="tender__header-dates-end">
                            <span class="tender__header-dates-title">Окончание торгов:</span>
                            <span class="tender__header-dates-time gray-text">{{ tender.date_end | date('DD.MM.YYYY, HH:mm') }}</span>
                            <span
                                v-show="tender.prolong"
                                class="tender__header-dates-time green-text"
                            > + {{ Math.floor(tender.prolong/60) }} мин</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="tender.tasks && tender.tasks.length"
            class="tender__main"
        >
            <div class="tender__main-body">
                <div class="tender__main-block">
                    <div class="tender__main-field tender__main-title">
                        Пункт отправления
                    </div>
                    <div class="tender__main-field">
                        {{ tender.tasks[0].point_from }}
                    </div>
                </div>
                <div class="tender__main-block">
                    <div class="tender__main-field tender__main-title">
                        Пункт назначения
                    </div>
                    <div class="tender__main-field">
                        {{ tender.tasks[0].point_to }}
                    </div>
                </div>
                <div class="tender__main-block">
                    <div class="tender__main-field tender__main-title">
                        Дата погрузки
                    </div>
                    <div class="tender__main-field">
                        {{ tender.tasks[0].date_start | date('DD.MM.YYYY') }}
                    </div>
                </div>
                <div class="tender__main-block">
                    <div class="tender__main-field tender__main-title">
                        Дата выгрузки
                    </div>
                    <div class="tender__main-field">
                        {{ tender.tasks[0].date_end | date('DD.MM.YYYY') }}
                    </div>
                </div>
                <div class="tender__main-block">
                    <div class="tender__main-field tender__main-title">
                        Вес / кол-во паллет
                    </div>
                    <div class="tender__main-field">
                        {{ tender.tasks[0].weight }}
                    </div>
                </div>
                <div class="tender__main-block">
                    <div class="tender__main-field tender__main-title">
                        Тип выгрузки
                    </div>
                    <div class="tender__main-field">
                        {{ tender.tasks[0].vehicle }}
                    </div>
                </div>
            </div>
        </div>


        <div
            v-if="tender.tasks && tender.tasks.length"
            class="tender__info"
        >
            <div class="tender__info-left">
                <div class="field m--small">
                    <div class="field__title">
                        Время выгрузки
                    </div>
                    <div
                        id="shipmentTime"
                        class="field__input"
                    >
                        <input
                            type="text"
                            :value="tender.tasks[0].date_text ? tender.tasks[0].date_text : dateTimeFormat(tender.tasks[0].date_end)"
                            readonly
                        >
                    </div>
                </div>
                <div class="field">
                    <div class="field__title">
                        Адрес выгрузки
                    </div>
                    <div class="field__value m--address">
                        <span>{{ tender.tasks[0].address }}</span>
                        <a 
                            :href="get_map_link"
                            class="button"
                        />
                    </div>
                </div>
                <div class="field m--large">
                    <div class="field__title">
                        Требования к транспорту
                    </div>
                    <div
                        class="field__input v-html text"
                        v-html="tender.comment"
                    />
                </div>
                <div class="field m--large">
                    <div class="field__title">
                        Комментарий менеджера
                    </div>
                    <div
                        class="field__input v-html text"
                        v-html="tender.manager_comment"
                    />
                </div>
            </div>
            <div class="tender__info-right">
                <div class="tender__info-bets">
                    <div class="tender__info-bet">
                        <div class="m--sum">
                            {{ tender.bet_init || 'нет' }}
                        </div>
                        <div class="m--text">
                            Начальная ставка
                        </div>
                    </div>
                    <div class="tender__info-bet m--best">
                        <div class="m--sum">
                            {{ tender.bet_win && tender.bet_win.bet ? tender.bet_win.bet : 'нет' }}
                        </div>
                        <div class="m--text">
                            Лучшая ставка
                        </div>
                    </div>
                    <div class="tender__info-bet">
                        <div class="m--sum">
                            {{ tender.bet_step }}
                        </div>
                        <div class="m--text">
                            Шаг cтавки
                        </div>
                    </div>
                </div>
                <div
                    v-show="isActive && !isExpired && !$store.state.role"
                    class="tender__info-bets"
                >
                    <form
                        class="field m--new-bet"
                        :class="errors.bet ? 'is-error' : ''"
                        @submit.prevent="createNewBet"
                    >
                        <div class="field__title">
                            Новая ставка
                        </div>
                        <div class="field-input m--bet">
                            <input
                                v-model.number="bet"
                                type="number"
                                name="bet"
                                placeholder="Введите новую ставку"
                            >
                            <button
                                type="submit"
                                class="button button-icon m--check"
                            />
                        </div>
                        <div
                            v-if="errors.bet"
                            class="field__error bet"
                        >
                            <ul class="field__error-list">
                                <li
                                    v-for="error in errors.bet"
                                    :key="error"
                                >
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                    </form>
                    <button
                        class="button button-transparent m--close"
                        @click="cancelBet"
                    >
                        Снять ставку
                    </button>
                </div>
                <div
                    v-if="betsHistory.length"
                    class="tender__bets"
                >
                    <div class="tender__bets-header h2">
                        История ставок:
                    </div>
                    <div class="tender__bets-table">
                        <div class="tender__bets-table-header">
                            <div>Поставщик</div>
                            <div>Время ставки</div>
                            <div class="m--right">
                                Ставка
                            </div>
                        </div>
                        <div
                            v-for="pastBet in betsHistory"
                            :key="pastBet.id"
                            class="tender__bets-table-row"
                        >
                            <div class="tender__bets-table-cell m--supplier">
                                {{ pastBet.company_name }}
                            </div>
                            <div class="tender__bets-table-cell m--bet-time">
                                {{ historyFormattedDate(pastBet.pub_date) }}
                            </div>
                            <div class="tender__bets-table-cell m--bet-sum">
                                {{ pastBet.bet }}.00
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="tender.user_winner && (tender.user_winner.id === $store.getters.getUserId || tender.manager === $store.getters.getUserId)"
            class="tender__footer"
        >
            <div
                v-if="!tender.confirmed"
                class="tender__footer-status"
            >
                <div
                    v-if="!tender.confirmed"
                    class="tender__footer-status-item"
                >
                    <span class="m--icon m--wait" />
                    <span>Ожидает подтверждения</span>
                </div>
            </div>

            <div class="tender__footer-card">
                <div class="tender__footer-card-row">
                    <div class="tender__footer-card-cell">
                        <div class="tender__footer-card-cell-title">
                            Победитель
                        </div>
                        <div class="tender__footer-card-cell-info">
                            {{ tender.user_winner.company ? tender.user_winner.company.company_name : '' }}
                        </div>
                    </div>
                    <div class="tender__footer-card-cell">
                        <div class="tender__footer-card-cell-title">
                            Предложенная цена
                        </div>
                        <div class="tender__footer-card-cell-info">
                            {{ tender.bet_win && tender.bet_win.bet ? tender.bet_win.bet : '' }}
                        </div>
                    </div>
                </div>
                <div class="tender__footer-card-row">
                    <div class="tender__footer-card-cell">
                        <div class="tender__footer-card-cell-title">
                            Водитель
                            <span
                                class="tender__footer-card-cell-add"
                                @click="addDriver"
                            />
                            <span
                                v-if="driver"
                                class="tender__footer-card-cell-edit"
                                @click="supplyDriver"
                            />
                        </div>
                        <div class="tender__footer-card-cell-info">
                            <div v-if="driver">
                                <div><b>ФИО:</b> {{ driver.name }}</div>
                                <div><b>Паспорт:</b> {{ driver.passport_serial }} {{ driver.passport_number }}</div>
                                <div><b>Водительское удостоверение:</b> {{ driver.license }}</div>
                                <div><b>Телефон:</b> {{ driver.phone }}</div>
                            </div>
                            <button
                                v-if="!driver"
                                class="button button-transparent"
                                @click="supplyDriver"
                            >
                                Внести информацию
                            </button>
                        </div>
                    </div>
                    <div class="tender__footer-card-cell">
                        <div class="tender__footer-card-cell-title">
                            Транспортное средство
                            <span
                                class="tender__footer-card-cell-add"
                                @click="addCar"
                            />
                            <span
                                v-if="car"
                                class="tender__footer-card-cell-edit"
                                @click="supplyVehicle"
                            />
                        </div>
                        <div class="tender__footer-card-cell-info">
                            <div v-if="car">
                                <div><b>Марка:</b> {{ car.brand }}</div>
                                <div><b>Номер:</b> {{ car.gos_num }}</div>
                                <div><b>Номер прицепа:</b> {{ car.trailer_num }}</div>
                            </div>
                            <button
                                v-if="!car"
                                class="button button-transparent"
                                @click="supplyVehicle"
                            >
                                Внести информацию
                            </button>
                        </div>
                    </div>
                    <div class="tender__footer-card-cell">
                        <div class="tender__footer-card-cell-title">
                            Время подачи машины
                            <span
                                v-if="time"
                                class="tender__footer-card-cell-edit"
                                @click="supplyCarTime"
                            />
                        </div>
                        <div class="tender__footer-card-cell-info">
                            <span v-if="time">
                                {{ time.date | date('DD.MM.YYYY') }} {{ time.time }}
                            </span>
                            <button
                                v-else
                                class="button button-transparent"
                                @click="supplyCarTime"
                            >
                                Внести информацию
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!$store.state.role"
                    class="tender__footer-card-buttons"
                >
                    <button
                        class="button button-transparent m--close"
                        @click="denyWinner"
                    >
                        Отказаться от тендера
                    </button>
                </div>
                <div
                    v-if="$store.state.role"
                    class="tender__footer-card-buttons"
                >
                    <button
                        v-if="!tender.confirmed"
                        class="button button-green m--check"
                        @click="confirm"
                    >
                        Подтвердить данные
                    </button>
                    <button
                        v-if="tender.confirmed"
                        class="button button-transparent m--close"
                        @click="denyConfirmation"
                    >
                        Отменить подтверждение
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SelectDriverModal from "@/components/SelectDriverModal";
    import SelectCarModal from "@/components/SelectCarModal";
    import SelectTimeModal from "@/components/SelectTimeModal";
    import { app, chat } from "@/services";
    import { parseDate } from '@/utils/helpers';
    import AddDriverModal from "@/components/AddDriverModal";
    import AddCarModal from "@/components/AddCarModal";

    export default {
        name: "Tender",
        data() {
            return {
                id: null,
                tender: undefined,
                bet: '',
                betsHistory: [],
                driver: null,
                car: null,
                time: null,
                timerText: 'До окончания торгов\nосталось',
                timerTime: '',
                timerClass: '',
                timer: undefined,
                seconds: undefined,
                errors: {
                    bet: ''
                }
            }
        },
        computed: {
            get_map_link: function () {
                return 'https://yandex.ru/maps/?text=' + this.tender.tasks[0].address
            },
            duration() {
                let dateNow = new Date(this.tender.datetime_now).getTime()
                let timeZone = new Date().getTimezoneOffset() * 60 * 1000 // минуты * минут в часе * милисекунды
                let zoneDateNow = dateNow - timeZone
                return (parseDate(this.tender.date_end + '+07:00', 'isoDateTime') - zoneDateNow)/1000;
            },
            isExpired() {
                return this.duration <= 0;
            },
            isActive() {
                let dateNow = new Date(this.tender.datetime_now).getTime()
                let timeZone = new Date().getTimezoneOffset() * 60 * 1000  // минуты * минут в часе * милисекунды
                let zoneDateNow = dateNow - timeZone
                return (parseDate(this.tender.date_start + '+07:00', 'isoDateTime') - zoneDateNow)/1000 <= 0;
            }
        },
        watch: {
            seconds(value) {
                let days = parseInt(value / 60 / 60 / 24, 10);
                let hours = parseInt(value / 60 / 60, 10);
                let minutes = parseInt(value / 60 % 60, 10);
                let seconds = parseInt(value % 60, 10);

                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;

                this.timerTime = `${hours}:${minutes}:${seconds}`;
                if (value < 0) {
                    this.timerClass = 'expired';
                    this.timerText = "Торги завершены";
                    this.timerTime = '';
                    this.stopTimer();
                }

                if (hours <= 1) {
                    this.timerClass = 'expires-soon';
                } else if (days === 0) {
                    this.timerClass = 'expires-today';
                } else {
                    this.timerClass = 'expires-sometime';
                }
            }
        },
        created() {
            this.id = this.$route.params.id
        },
        mounted() {
            this.getFullTenderInfo(this.id).then(() => {
                this.startTimer();
            })
        },
        destroyed() {
            this.stopTimer();
        },
        methods: {
            startTimer() {
                if (!this.isExpired && this.isActive) {
                    this.seconds = this.duration;
                    this.timer = setInterval(() => {
                        this.seconds--;
                    }, 1000);
                } else if (!this.isExpired && !this.isActive) {
                    this.timerClass = 'is-hidden';
                } else {
                    this.timerClass = 'expired';
                    this.timerText = "Торги завершены";
                }
            },
            stopTimer() {
                clearTimeout(this.timer);
                clearInterval(this.timer);
            },
            historyFormattedDate(dateTime) {
                if (!dateTime) return "";
                const d = new Date(dateTime);
                const monthA = 'января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря'.split(',');
                return `${d.getDate()} ${monthA[d.getMonth()]} ${d.getFullYear()} г. ${d.toLocaleTimeString('ru', {
                    hour: '2-digit',
                    minute: '2-digit'
                })}`
            },
            dateTimeFormat(dateTime) {
                if (!dateTime) return "";
                const date = new Date(dateTime);
                return date.toLocaleTimeString(['ru'], {
                    hour: '2-digit',
                    minute: '2-digit'
                }) + " " + date.toLocaleDateString('ru');
            },
            getFullTenderInfo(id) {
                return app.getTender(id)
                    .then(res => {
                        this.tender = res;
                        this.driver = this.tender.vendor_driver
                        this.car = this.tender.vendor_car
                        this.time = this.tender.time_delivery
                        return this.fetchHistory();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            fetchHistory() {
                return app.getTenderBets(this.tender.id)
                    .then(res => {
                        this.betsHistory = res;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            gotoChat() {
                chat.getOrCreateTenderChat(
                    this.id,
                    this.$store.getters.getUserId
                ).then((res) => {
                    this.$router.push({path: `/chat?chatId=${res}`})
                })
            },
            createNewBet() {
                this.errors.bet = '';
                if (this.bet > 0) {
                    app.createBet(this.tender.id, {
                        'bet': this.bet
                    }).then(() => {
                        this.getFullTenderInfo(this.tender.id)
                    }).catch(error => {
                        if (error && error.response && error.response.data) {
                            this.errors.bet = [error.response.data];
                        }
                    })
                }
            },
            cancelBet() {
                app.deleteLastBet(this.tender.id, this.$store.state.id)
                    .then(() => {
                        this.getFullTenderInfo(this.tender.id)
                    })
            },
            denyWinner() {
                app.denyWinner(this.tender.id)
                    .then(() => {
                        this.getFullTenderInfo(this.tender.id)
                    })
            },
            denyConfirmation() {
                app.confirmTender(this.tender.id, false)
                    .then(() => {
                        this.tender.confirmed = false;
                    })
            },
            confirm() {
                app.confirmTender(this.tender.id, true)
                    .then(() => {
                        this.tender.confirmed = true;
                    })
            },
            addCar() {
                this.$modal.show(AddCarModal, {
                    mode: 'create'
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.supplyVehicle()
                });
            },
            addDriver() {
                this.$modal.show(AddDriverModal, {
                    mode: 'create'
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.supplyDriver()
                });
            },
            supplyDriver() {
                this.$modal.show(SelectDriverModal, {
                    initDriver: this.driver,
                    tenderId: this.tender.id
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'before-close': (driver) => {
                        if (driver && driver.params) {
                            this.driver = driver.params
                        }
                    }
                });
            },
            supplyVehicle() {
                this.$modal.show(SelectCarModal, {
                    initCar: this.car,
                    tenderId: this.tender.id
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'before-close': (car) => {
                        if (car && car.params) {
                            this.car = car.params
                        }
                    }
                });
            },
            supplyCarTime() {
                this.$modal.show(SelectTimeModal, {
                    initTime: this.time,
                    dateStart: this.$options.filters.date(this.tender.tasks && this.tender.tasks.length > 0 ? this.tender.tasks[0].date_start : '', 'YYYY-MM-DD'),
                    tenderId: this.tender.id
                }, {
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                    styles: { overflow: 'initial' }
                }, {
                    'before-close': (time) => {
                        if (time && time.params) {
                            this.time = time.params
                        }
                    }
                });
            }
        }


    }
</script>
