<template>
    <div class="modal">
        <div class="modal__header h1">
            Выбор машины
        </div>
        <div class="modal__body modal__form">
            <form
                class="select-modal__search field m--small m--red m--with-button"
                @submit.prevent="searchCar"
            >
                <div class="field__input">
                    <input
                        v-model="searchQuery"
                        type="text"
                    >
                </div>
                <button
                    class="button button-icon m--search"
                    @click="searchCar"
                />
            </form>
            <div
                v-for="car in carsToShow"
                :key="car.id"
                class="select-modal__row field__row m--card"
                :class="{ 'is-active': carActive(car) }"
                @click="selectCar(car)"
            >
                <div class="select-modal__field field">
                    <div class="field__title">
                        Марка/Модель
                    </div>
                    <div class="field__input">
                        {{ car.brand }}
                    </div>
                </div>
                <div class="select-modal__field field m--min-width-120">
                    <div class="field__title ">
                        Гос. номер
                    </div>
                    <div class="field__input">
                        {{ car.gos_num }}
                    </div>
                </div>
                <div class="select-modal__field field m--min-width-120">
                    <div class="field__title">
                        Тип выгр.
                    </div>
                    <div class="field__input">
                        {{ car.unloading }}
                    </div>
                </div>
                <div class="select-modal__field field m--min-width-100">
                    <div class="field__title">
                        Тип ТС
                    </div>
                    <div class="field__input">
                        {{ car.ts_type }}
                    </div>
                </div>
            </div>
        </div>
        <div class="modal__footer m--flex">
            <button
                class="button button-red"
                :disabled="!isActive"
                @click="save"
            >
                Сохранить
            </button>
            <button
                class="button button-transparent"
                @click="cancel"
            >
                Отменить
            </button>
        </div>
    </div>
</template>

<script>
    import { users, app } from '@/services';

    export default {
        name: "SelectCarModal",
        props: {
            initCar: {
                type: Object,
                default() { return undefined }
            },
            tenderId: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                cars: [],
                carVal: null,
                searchQuery: ''
            }
        },
        computed: {
            carsToShow: function () {
                return this.cars.filter(car => {
                    if (this.searchQuery) {
                        return (car.publication > 0 && car.gos_num.toLowerCase().includes(this.searchQuery.toLowerCase()));
                    }
                    return car.publication > 0;
                });
            },
            isActive: function () {
                return this.carVal && this.carVal.id > 0;
            }
        },
        created() {
            this.fetchCars();
        },
        methods: {
            fetchCars() {
                users.getCars().then(res => {
                    this.cars = res
                    if (this.initCar) {
                        this.carVal = this.cars.find(x => x.id === this.initCar.id);
                    }
                });
            },
            carActive(car) {
                return this.carVal && this.carVal.id > 0 && car.id === this.carVal.id
            },
            searchCar() {
                // this.filter = this.cars.filter(c => c.name.includes(this.$refs.carNameInput.value));
            },
            selectCar(car) {
                if (this.carVal && this.carVal.id === car.id) {
                    this.carVal = undefined;
                } else {
                    this.carVal = car;
                }
            },
            save() {
                app.setupTender(this.tenderId, this.carVal.id)
                    .then(() => this.close(this.carVal))
                    .catch((error) => {
                        if (error && error.response && error.response.data) {
                            this.errors = error.response.data;
                        }
                    });
            },
            cancel() {
                this.close();
            },
            close(car) {
                this.$emit('close', car ? car : this.initCar);
            },
        }
    }
</script>