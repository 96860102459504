<template>
    <div class="vendor">
        <div class="vendor__header h1">
            {{ companyName }}
        </div>
        <VendorMenu
            :id="id"
            :name="companyName"
        />
        <div
            v-if="drivers && drivers.length"
            class="vendor__drivers-table table"
        >
            <div class="vendor__drivers-table-header table__header">
                <div class="table__header-cell">
                    ФИО
                </div>
                <div class="table__header-cell">
                    Паспорт
                </div>
                <div class="table__header-cell">
                    Водит. удо&shy;сто&shy;верение
                </div>
                <div class="table__header-cell">
                    Телефон
                </div>
                <div class="table__header-cell">
                    Стаж, лет
                </div>
                <div class="table__header-cell">
                    Статус
                </div>
                <div class="table__header-cell">
                    Ваш ком&shy;мен&shy;тарий
                </div>
                <div class="table__header-cell" />
            </div>
            <VendorDriversItem
                v-for="driver in drivers"
                :key="driver.id"
                :driver="driver"
                @editDriver="editDriver(driver)"
                @setDriverStatus="setDriverStatus"
            />
        </div>
        <div
            v-else
            class="account__card"
        >
            Поставщик не предоставил информацию о водителях
        </div>
    </div>
</template>

<script>
    import VendorMenu from "@/components/VendorMenu";
    import VendorDriversItem from "@/components/VendorDriversItem";
    import { users } from "@/services";
    import AddDriverModal from "../components/AddDriverModal";

    export default {
        name: "AccountDrivers",
        components: {
            VendorMenu,
            VendorDriversItem
        },
        props: {
            name: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                drivers: [],
                id: Number.parseInt(this.$route.params.id),
                companyName: ''
            }
        },
        beforeMount() {
            this.getDrivers(this.id);
            if (this.name) {
                this.companyName = this.name;
            } else {
                this.getAccountInfo(this.id)
            }
        },
        methods: {
            getAccountInfo(id) {
                users.getProfile(id).then((res) => {
                    this.companyName = res.company.company_name
                }).catch((error) => {
                    console.log(error);
                })
            },
            getDrivers(id) {
                users.getVendorDrivers(id)
                    .then(response => this.drivers = response);
            },
            editDriver(driver) {
                this.$modal.show(AddDriverModal, {
                    mode: 'edit',
                    initDriver: driver,
                    statusPermission: true
                },{
                    adaptive: true,
                    width: '90%',
                    maxWidth: 800,
                    height: 'auto',
                }, {
                    'closed': () => this.getDrivers(this.id)
                });
            },
            setDriverStatus(driverId, status) {
                users.setDriverActive(driverId, status)
                    .then(() => {
                        this.getDrivers(this.id)
                    });
            }
        }
    }
</script>