<template>
    <div class="modal">
        <div class="modal__header h1">
            {{ mode === 'create' ? 'Добавить новую машину' : 'Редактировать машину' }}
        </div>
        <div class="modal__body modal__form">
            <div class="field__row">
                <field
                    v-model="car.brand"
                    type="text"
                    name="brand"
                    label="Марка/Модель"
                    placeholder="Введите марку"
                    :small="true"
                    :required="true"
                    :errors="errors.brand"
                />
                <field
                    v-model="car.gos_num"
                    type="mask"
                    mask="A 999 AA 9{2,3}"
                    name="gos_num"
                    label="Гос. номер"
                    placeholder="Введите номер"
                    :small="true"
                    :required="true"
                    :errors="errors.gos_num"
                />
            </div>
            <div class="field__row">
                <field
                    v-model="car.trailer_num"
                    type="mask"
                    mask="AA 9999"
                    name="trailer_num"
                    label="Номер прицепа"
                    placeholder="Введите номер"
                    :small="true"
                    :errors="errors.trailer_num"
                />
                <field
                    v-model="car.carrying"
                    type="text"
                    name="carrying"
                    label="Грузоподъемность"
                    placeholder="Введите грузоподъебность"
                    :small="true"
                    :errors="errors.carrying"
                />
            </div>
            <div class="field__row">
                <field
                    v-model="car.unloading"
                    :options="unloadingTypes"
                    type="select"
                    name="unloading"
                    label="Тип выгрузки"
                    placeholder="Тип выгрузки"
                    :small="true"
                    :errors="errors.unloading"
                />
                <field
                    v-model="car.ts_type"
                    :options="tsTypes"
                    type="select"
                    name="ts_type"
                    label="Тип ТС"
                    placeholder="Тип ТС"
                    :small="true"
                    :errors="errors.ts_type"
                />
            </div>
            <field
                v-model="car.comment"
                type="textarea"
                name="comment"
                label="Комментарий"
                placeholder="Введите комментарий"
                :small="true"
                :full="true"
                :errors="errors.comment"
            />
            <field
                v-model="car.manager_comment"
                type="textarea"
                name="manager_comment"
                label="Комментарий менеджера"
                placeholder="Введите комментарий"
                :small="true"
                :full="true"
                :disabled="!statusPermission"
                :errors="errors.manager_comment"
            />
            <div
                v-if="statusPermission"
                class="field m--checkbox"
            >
                <input
                    id="publication"
                    v-model="car.publication"
                    type="checkbox"
                >
                <label for="publication">Активен</label>
            </div>
        </div>
        <div class="modal__footer m--flex">
            <button
                class="button button-red"
                @click="save"
            >
                Сохранить
            </button>
            <button
                class="button button-transparent"
                @click="cancel"
            >
                Отменить
            </button>
        </div>
    </div>
</template>

<script>
    import { users } from '@/services'
    import Field from "@/components/field";

    export default {
        name: "AddCarModal",
        components: {
            Field
        },
        props: {
            mode: {
                type: String,
                default: 'create'
            },
            initCar: {
                type: Object,
                default() {
                    return {
                        unloading: 'любая',
                        ts_type: 'тент'
                    }
                }
            },
            statusPermission: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                car: {},
                errors: {},
                unloadingTypes: [
                    { name: "Любая", value: "любая" },
                    { name: "Боковая", value: "боковая" },
                    { name: "Верхняя", value: "верхняя" },
                    { name: "Задняя", value: "задняя" }
                ],
                tsTypes: [
                    { name: "Тент", value: "тент" },
                    { name: "Борт", value: "борт" }
                ],
            }
        },
        // beforeMount() {
        //     this.car.unloading = this.car.unloading || "любая";
        //     this.car.ts_type = this.car.ts_type || "тент";
        // },
        created() {
            this.car = Object.assign({}, this.initCar);
            this.car.unloading = this.unloadingTypes.find(x => this.car.unloading.includes(x.value));
            this.car.ts_type = this.tsTypes.find(x => this.car.ts_type.includes(x.value));
        },
        methods: {
            cancel() {
                this.close();
            },
            close() {
                this.clearErrors();
                this.$emit('close');
            },
            save() {
                this.clearErrors();
                // this.car.unloading = this.car.unloading.toLowerCase();
                // this.car.unloading = this.car.unloading.value;
                // this.car.ts_type = this.car.ts_type.value;
                let params = Object.assign({}, this.car, {
                    unloading: this.car.unloading.value,
                    ts_type: this.car.ts_type.value
                });
                switch (this.mode) {
                case 'create':
                    users.addCar(params)
                        .then(() => this.close())
                        .catch((error) => {
                            if (error && error.response && error.response.data) {
                                this.errors = error.response.data;
                            }
                        });
                    break;
                case 'edit':
                    users.updateCar(params)
                        .then(() => this.close())
                        .catch((error) => {
                            if (error && error.response && error.response.data) {
                                this.errors = error.response.data;
                            }
                        });
                    break;
                }
            },
            clearErrors() {
                this.errors = {}
            }
        }
    }
</script>