<template>
    <div class="news">
        <ToolBarNews
            v-if="news.length > 0"
            ref="toolBar"
            :page="pageNum"
            :pgCount="pageCount"
        />
        <div
            v-if="news.length > 0"
            class="news__items"
        >
            <div
                v-for="news_item in news"
                :key="news_item.id"
                class="news__item"
            >
                <div class="news__item-header">
                    <div class="news__item-title">
                        {{ news_item.title }}
                    </div>
                    <div class="news__item-info">
                        <span>
                            <strong>Автор:</strong> {{ news_item.author || 'Аноним' }}
                        </span>
                        <span class="m--nowrap">
                            {{ news_item.date | date('DD.MM.YYYY, HH:mm') }}
                        </span>
                    </div>
                </div>
                <div
                    class="news__item-text text v-html"
                    v-html="news_item.text"
                />
                <!-- <a href="#" class="news__item-more">
                    Подробнее
                </a> -->
            </div>
        </div>
        <div
            v-else
            class="news__items"
        >
            <div class="news__items-empty">
                Здесь пока пусто.. Ждем новостей
            </div>
        </div>
    </div>
</template>

<script>
    import { app } from '@/services'
    import ToolBarNews from "@/components/ToolBarNews";

    export default {
        name: "News",
        components: {
            ToolBarNews,
        },
        data() {
            return {
                news: [],
                pageNum: 1,
                pageCount: 0
            }
        },
        created() {
            this.fetchNews()
        },
        methods: {
            getNewsPerPage() {
                return this.$refs.toolBar && this.$refs.toolBar.tendersPerPage.value || 10;
            },
            fetchNews(pageNum) {
                this.pageNum = pageNum || 1;
                const limit = this.getNewsPerPage();
                const offset = (this.pageNum - 1) * limit;
                app.getNewsList(limit, offset, this.filter).then(res =>{
                    if (res.count <= offset && this.pageNum > 1){
                        /* Если пытаемся загрузить страницу, на которой по факту нет записей, то перебрасываем на первую */
                        this.fetchNews(1, this.filter);
                        return null;
                    } else {
                        this.news = res.results;
                        this.pageCount = res.count;
                    }
                }).catch(err => console.error(err));

            },
            applyFilter(filter) {
                this.filter = filter;
                this.fetchNews(1);
            }
        }
    }
</script>