<template>
    <div class="tabs">
        <div class="tabs__item">
            <router-link :to="{ name: 'vendorInfo', params: { name: name, id: id }}">
                Информация о компании
            </router-link>
        </div>
        <div class="tabs__item">
            <router-link :to="{ name: 'vendorDocs', params: { name: name, id: id }}">
                Документы
            </router-link>
        </div>
        <div class="tabs__item">
            <router-link :to="{ name: 'vendorDrivers', params: { name: name, id: id }}">
                Водители
            </router-link>
        </div>
        <div class="tabs__item">
            <router-link :to="{ name: 'vendorCars', params: { name: name, id: id }}">
                Машины
            </router-link>
        </div>
        <div class="tabs__item">
            <router-link :to="{ name: 'vendorCities', params: { name: name, id: id }}">
                Города
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VendorMenu",
        props: {
            id: {
                type: Number,
                required: true
            },
            name: {
                type: String,
                default: ''
            }
        }
    }
</script>
