<template>
    <div
        class="tender-item"
        :class="itemOpened ? 'is-open' : ''"
        @click="goToTender(tender)"
    >
        <div class="tender-item__header">
            <div class="tender-item__header-block m--wrap">
                <div class="tender-item__header-name">
                    <span class="tender-item__header-code">
                        №{{ tender.code }}
                    </span>
                    {{ tender.name }}
                </div>
                <div
                    v-if="!archive && ['новый','играет'].includes(tender.status)"
                    class="tender-item__header-status"
                >
                    {{ tender.status && tender.status.capitalize() }}
                </div>
                <div
                    v-if="!archive && ['открытый','сервисный'].includes(tender.t_type)"
                    class="tender-item__header-status"
                >
                    {{ tender.t_type && tender.t_type.capitalize() }}
                </div>
                <div
                    v-if="!archive && tender.tasks && tender.tasks.length > 0"
                    class="tender-item__header-status end-point"
                >
                    {{ tender.tasks[0].point_to }}
                </div>
                <div
                    v-if="$store.getters.getUserRole && tender.user_winner && tender.user_winner.id"
                    class="tender-item__header-status"
                >
                    {{ tender.user_winner.company ? tender.user_winner.company.company_name : tender.user_winner.name }}
                </div>
            </div>
            <div
                v-if="archive"
                class="tender-item__header-block"
            >
                <div
                    v-if="!['новый','играет'].includes(tender.status) && tender.user_declined && tender.declined"
                    class="tender__header-status m--no-docs"
                >
                    ТК отказалось.<br>{{ tender.user_declined.company ? tender.user_declined.company.company_name : '' }}
                </div>
                <div class="tender-item__header-status is-played">
                    <div
                        v-if="tender.user_winner && tender.user_winner.id"
                        class="tender-item__header-winner"
                    />
                    Тендер сыграл
                </div>
                <div
                    class="tender-item__header-slide"
                    @click.stop="slide"
                />
            </div>
            <div
                v-else 
                class="tender-item__header-block"
            >
                <div
                    class="tender-item__header-slide"
                    @click.stop="slide"
                />
                <div
                    v-if="isExpired && tender.status !== 'архив'"
                    class="tender-item__header-status docs-status"
                    :class="[tender.vendor_car && tender.vendor_driver ? 'has-docs' : 'no-docs']"
                >
                    {{ tender.vendor_car && tender.vendor_driver ? 'Документы получены' : 'Документы не получены' }}
                </div>
                <div
                    v-if="!['новый','играет'].includes(tender.status) && tender.user_declined && tender.declined"
                    class="tender-item__header-status docs-status no-docs"
                >
                    ТК отказалось.<br>{{ tender.user_declined.company ? tender.user_declined.company.company_name : '' }}
                </div>
                <div
                    v-else-if="!['новый','играет'].includes(tender.status) && tender.user_winner && tender.user_winner.id"
                    class="tender-item__header-status"
                    :class="tender.confirmed ? 'confirmed' : 'waiting'"
                >
                    {{ tender.confirmed ? 'Подтвержден' : 'Ожидает подтверждения' }}
                </div>
                <div
                    v-if="!isExpired"
                    class="tender-item__header-timer timer"
                    :class="[timerClass, !isExpired && isActive? 'has-timer' : '']"
                >
                    <div class="timer__icon" />
                    <div class="timer__status">
                        {{ isExpired ? 'Торги завершены' : '' }}
                    </div>
                    <div
                        v-if="!isExpired && isActive"
                        class="timer__counter"
                    >
                        {{ timerTime }}
                    </div>
                </div>
                <div
                    v-else
                    class="tender-item__header-status is-played"
                >
                    <div
                        v-if="tender.user_winner && tender.user_winner.id"
                        class="tender-item__header-winner"
                    />
                    Тендер сыграл
                </div>
                <div
                    v-if="tender.status === 'новый' && !isActive && !isExpired"
                    class="tender-item__header-auction"
                >
                    <div class="tender-item__header-auction-start">
                        <span class="tender-item__header-auction-title">
                            Начало торгов:
                        </span>
                        <span class="tender-item__header-auction-time red-text">
                            {{ tender.date_start | date('DD.MM.YYYY, HH:mm') }}
                        </span>
                    </div>
                    <div class="tender-item__header-auction-end">
                        <span class="tender-item__header-auction-title">
                            Окончание торгов:
                        </span>
                        <span class="tender-item__header-auction-time red-text">
                            {{ tender.date_end | date('DD.MM.YYYY, HH:mm') }}
                        </span>
                        <span
                            v-show="tender.prolong"
                            class="tender-item__header-auction-time green-text"
                        >
                            + {{ Math.floor(tender.prolong / 60) }} мин
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="tender-item__form">
            <div class="tender-item__form-block">
                <div class="tender-item__form-field tender-item__form-title">
                    Пункт отправления
                </div>
                <div class="tender-item__form-field tender-item__form-value">
                    {{ tender.tasks && tender.tasks.length > 0 ? tender.tasks[0].point_from : '' }}
                </div>
            </div>
            <div class="tender-item__form-block">
                <div class="tender-item__form-field tender-item__form-title">
                    Пункт назначения
                </div>
                <div class="tender-item__form-field tender-item__form-value">
                    {{ tender.tasks && tender.tasks.length > 0 ? tender.tasks[0].point_to : '' }}
                </div>
            </div>
            <div class="tender-item__form-block">
                <div class="tender-item__form-field tender-item__form-title">
                    Дата погрузки
                </div>
                <div class="tender-item__form-field tender-item__form-value">
                    {{ tender.tasks && tender.tasks.length > 0 ? tender.tasks[0].date_start : '' | date('DD.MM.YYYY') }}
                </div>
            </div>
            <div class="tender-item__form-block">
                <div class="tender-item__form-field tender-item__form-title">
                    Дата выгрузки
                </div>
                <div class="tender-item__form-field tender-item__form-value">
                    {{ tender.tasks && tender.tasks.length > 0 ? tender.tasks[0].date_end : '' | date('DD.MM.YYYY') }}
                </div>
            </div>
            <div class="tender-item__form-block">
                <div class="tender-item__form-field tender-item__form-title">
                    Вес / кол-во паллет
                </div>
                <div class="tender-item__form-field tender-item__form-value">
                    {{ tender.tasks && tender.tasks.length > 0 ? tender.tasks[0].weight : '' }}
                </div>
            </div>
            <div class="tender-item__form-block">
                <div class="tender-item__form-field tender-item__form-title">
                    Тип выгрузки
                </div>
                <div class="tender-item__form-field tender-item__form-value">
                    {{ tender.tasks && tender.tasks.length > 0 ? tender.tasks[0].vehicle : '' }}
                </div>
            </div>
            <div class="tender-item__form-block">
                <div class="tender-item__form-field tender-item__form-title">
                    Начальная цена
                </div>
                <div class="tender-item__form-field tender-item__form-value">
                    {{ tender.bet_init ? tender.bet_init : '—' }}
                </div>
            </div>
            <div class="tender-item__form-block">
                <div class="tender-item__form-field tender-item__form-title">
                    Лучшая ставка
                </div>
                <div
                    class="tender-item__form-field tender-item__form-value"
                    :class="tender.bet_win && tender.bet_win.user === $store.getters.getUserId ? 'is-winner' : ''"
                >
                    {{ tender.bet_win && tender.bet_win.bet ? tender.bet_win.bet : '—' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { parseDate } from '@/utils/helpers';

    export default {
        props: {
            tender: {
                type: Object,
                default() { return {}; }
            },
            archive: {
                type: Boolean,
                default() { return false }
            }
        },
        data() {
            return {
                timerText: 'До окончания торгов\nосталось',
                timerTime: '',
                timerClass: '',
                timer: undefined,
                seconds: undefined,
                itemOpened: false
            }
        },
        computed: {
            role() {
                return this.$store.getters.getUserRole
            },
            duration() {
                let dateNow = new Date(this.tender.datetime_now).getTime()
                let timeZone = new Date().getTimezoneOffset() * 60 * 1000  // минуты * минут в часе * милисекунды
                let zoneDateNow = dateNow - timeZone
                return (parseDate(this.tender.date_end + '+07:00', 'isoDateTime') - zoneDateNow)/1000;
            },
            isExpired() {
                return this.duration <= 0;
            },
            isActive() {
                let dateNow = new Date(this.tender.datetime_now).getTime()
                let timeZone = new Date().getTimezoneOffset() * 60 * 1000  // минуты * минут в часе * милисекунды
                let zoneDateNow = dateNow - timeZone
                return (parseDate(this.tender.date_start + '+07:00', 'isoDateTime') - zoneDateNow)/1000 <= 0;
            }
        },
        watch: {
            seconds(value) {
                let days = parseInt(value / 60 / 60 / 24, 10);
                let hours = parseInt(value / 60 / 60, 10);
                let minutes = parseInt(value / 60 % 60, 10);
                let seconds = parseInt(value % 60, 10);

                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;

                this.timerTime = `${hours}:${minutes}:${seconds}`;
                if (value < 0) {
                    this.timerClass = 'expired';
                    this.timerText = "Торги завершены";
                    this.stopTimer();
                }

                if (hours <= 1) {
                    this.timerClass = 'expires-soon';
                } else if (days === 0) {
                    this.timerClass = 'expires-today';
                } else {
                    this.timerClass = 'expires-sometime';
                }
            }
        },
        mounted() {
            this.startTimer();
        },
        destroyed() {
            this.stopTimer();
        },
        methods: {
            startTimer() {
                if (!this.isExpired && this.isActive) {
                    this.seconds = this.duration;
                    this.timer = setInterval(() => {
                        this.seconds--;
                    }, 1000);
                } else if (!this.isExpired && !this.isActive) {
                    this.timerClass = 'is-hidden';
                } else {
                    this.timerClass = 'expired';
                    this.timerText = "Торги завершены";
                }
            },
            stopTimer() {
                clearTimeout(this.timer);
                clearInterval(this.timer);
            },
            goToTender(el) {
                let id = el.id
                this.$router.push({path: `/tenders/${id}`})
            },
            slide() {
                this.itemOpened = !this.itemOpened;
            }
        }
    }
</script>
