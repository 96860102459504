<template>
    <div class="notifications">
        <div
            v-for="note in notifications"
            :key="note.id"
            class="notifications__item notice"
        >
            <div class="notifications__item-title notice-title">
                {{ note.title }}
            </div>
            <div
                class="notifications__item-link text"
                v-html="note.link"
            />
            <div class="notifications__item-text text notice-text">
                {{ note.description }}
            </div>
        </div>
        <div
            v-if="notifications.length === 0"
            class="notifications__empty"
        >
            Здесь пока пусто.. Скоро придет уведомление
        </div>
    </div>
</template>

<script>
    import { app } from '@/services'
    export default {
        name: "Notifications",
        data() {
            return {
                notifications: []
            }
        },
        mounted() {
            this.fetchNotifications();
        },
        methods: {
            fetchNotifications() {
                app.getNotificationsList().then(res => {
                    this.notifications = res.results;
                }).catch(err => console.error(err));
            },
        }
    }
</script>
