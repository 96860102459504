<template>
    <div
        v-if="profile"
        class="account"
    >
        <AccountMenu v-if="!$store.state.role" />
        <div
            v-if="!$store.state.role && company"
            class="account__card"
        >
            <div class="account__card-title h1">
                {{ company.company_name }}
            </div>
            <div class="account__card-form">
                <div class="account__field field m--small">
                    <div class="account__field-title field__title">
                        Юридический адрес
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="company.company_addr"
                            name="company_addr"
                            type="text"
                        >
                    </div>
                </div>
                <div class="account__field field m--small">
                    <div class="account__field-title field__title">
                        Почтовый адрес
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="company.company_post_addr"
                            name="company_post_addr"
                            type="text"
                        >
                    </div>
                </div>
                <div class="account__field field m--small">
                    <div class="account__field-title field__title">
                        ИНН
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="company.company_inn"
                            name="company_inn"
                            type="text"
                        >
                    </div>
                </div>
                <div class="account__field field m--small">
                    <div class="account__field-title field__title">
                        Телефон/Факс
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="company.company_phone"
                            name="company_phone"
                            type="text"
                        >
                    </div>
                </div>
                <div class="account__field field m--small">
                    <div class="account__field-title field__title">
                        ФИО директора
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="company.company_seo"
                            name="company_seo"
                            type="text"
                        >
                    </div>
                </div>
                <div class="account__field field m--small">
                    <div class="account__field-title field__title">
                        Веб-сайт
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="company.company_site"
                            name="company_site"
                            type="text"
                        >
                    </div>
                </div>
                <div class="account__card-form-buttons">
                    <button
                        class="account__card-form-button button button-red"
                        @click="saveCompanyInfo"
                    >
                        Сохранить
                    </button>
                    <button
                        class="account__card-form-button button button-transparent"
                        @click="resetCompanyInfo"
                    >
                        Отменить
                    </button>
                </div>
            </div>
        </div>
        <div class="account__card">
            <div class="account__card-title h1">
                Контактная информация
            </div>
            <div class="account__card-form">
                <div class="account__field field m--small">
                    <div class="account__field-title field__title">
                        ФИО
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="profile.name"
                            name="profile_name"
                            type="text"
                        >
                    </div>
                </div>
                <div class="account__field field m--small">
                    <div class="account__field-title field__title">
                        Email
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="profile.email"
                            name="profile_email"
                            type="text"
                        >
                    </div>
                </div>
                <div class="account__field field m--small">
                    <div class="account__field-title field__title">
                        Телефон/ Факс
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="profile.phone"
                            name="profile_phone"
                            type="text"
                        >
                    </div>
                </div>
                <div class="account__field field m--small">
                    <div class="account__field-title field__title">
                        Дополнительный телефон
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="profile.extra_phone"
                            type="text"
                            name="profile_extra_phone"
                        >
                    </div>
                </div>
                <div class="account__field">
                    <input
                        id="notify_email"
                        v-model.lazy="profile.notify_email"
                        name="notify_email"
                        :value="profile.notify_email"
                        type="checkbox"
                        class="checkbox"
                    >
                    <label for="notify_email">
                        Email оповещения
                    </label>
                </div>
                <div class="account__card-form-buttons">
                    <button
                        class="account__card-form-button button button-red"
                        @click="setUserInfo"
                    >
                        Сохранить
                    </button>
                    <button
                        class="account__card-form-button button button-transparent"
                        @click="resetUserInfo"
                    >
                        Отменить
                    </button>
                </div>
            </div>
        </div>
        <div class="account__card">
            <div class="account__card-title h1">
                Смена пароля
            </div>
            <div class="account__card-form">
                <div
                    class="account__field field m--small"
                    :class="password_error.old_password ? 'is-error' : ''"
                >
                    <div class="account__field-title field__title">
                        Текущий пароль
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="passwords.old_password"
                            type="password"
                        >
                    </div>
                    <div
                        v-if="password_error.old_password"
                        class="account__field-error"
                    >
                        <ul class="account__field-error-list">
                            <li v-for="error in password_error.old_password">
                                {{ error }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div />
                <div
                    class="account__field field m--small"
                    :class="password_error.new_password || password_error.new_password ? 'is-error' : ''"
                >
                    <div class="account__field-title field__title">
                        Новый пароль
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="passwords.new_password"
                            type="password"
                        >
                    </div>
                </div>
                <div
                    class="account__field field m--small"
                    :class="password_error.new_password || password_error.new_password ? 'is-error' : ''"
                >
                    <div class="account__field-title field__title">
                        Повторите новый пароль
                    </div>
                    <div class="account__field-input field__input">
                        <input
                            v-model="passwords.repeat_password"
                            type="password"
                        >
                    </div>
                    <div
                        v-if="password_error.new_password || password_error.new_password"
                        class="account__field-error"
                    >
                        <ul class="account__field-error-list">
                            <li v-for="error in password_error.new_password">
                                {{ error }}
                            </li>
                            <li v-for="error in password_error.repeat_password">
                                {{ error }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="account__card-form-buttons">
                    <button
                        class="account__card-form-button button button-red"
                        @click="setNewPassword"
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AccountMenu from "@/components/AccountMenu";
    import { users } from '@/services'

    export default {
        name: "Account",
        components : {
            AccountMenu,
        },
        data() {
            return {
                profile: undefined,
                company: undefined,
                passwords: {
                    old_password: '',
                    new_password: '',
                    repeat_password: '',
                },
                password_error: {
                    old_password: '',
                    new_password: '',
                    repeat_password: '',
                },
                resetData: undefined
            }
        },
        watch: {
            'passwords.repeat_password': function (value) {
                this.clearErrors();
                if (value && this.passwords.new_password && value !== this.passwords.new_password) {
                    this.password_error.new_password = ['Пароли не совпадают'];
                }
            },
            'passwords.new_password': function (value) {
                this.clearErrors();
                if (value && this.passwords.repeat_password && value !== this.passwords.repeat_password) {
                    this.password_error.new_password = ['Пароли не совпадают'];
                }
            },
        },
        created() {
            this.getAccountInfo();
        },
        methods: {
            getAccountInfo() {
                users.getMyProfile().then((profile) => {
                    this.profile = profile;
                    this.company = profile.company;
                    this.resetData = Object.assign({}, profile);
                }).catch((error) => {
                    console.log(error);
                })
            },
            saveCompanyInfo() {
                users.setCompanyData(
                    this.company.id,
                    this.company.company_addr,
                    this.company.company_post_addr,
                    this.company.company_inn,
                    this.company.company_seo,
                    this.company.company_phone,
                    this.company.company_site,
                ).then(() => {
                    this.getAccountInfo()
                })
            },
            resetCompanyInfo() {
                this.company = this.resetData.company
            },
            setUserInfo() {
                users.setUserData(
                    this.profile.id,
                    this.profile.name,
                    this.profile.email,
                    this.profile.phone,
                    this.profile.extra_phone,
                    this.profile.notify_email
                ).then(() => {
                    this.getAccountInfo()
                })
            },
            resetUserInfo() {
                this.profile = this.resetData;
            },
            setNewPassword() {
                this.clearErrors();
                users.setNewPassword(
                    this.passwords.old_password,
                    this.passwords.new_password
                ).then(() => {
                    console.log("изменение пароля произошло успешно")
                }).catch((error) => {
                    console.log(error.response.data)
                    if (error && error.response && error.response.data) {
                        this.password_error = error.response.data;
                        console.log(error.response.data)
                    }
                });
            },
            clearErrors() {
                this.password_error = {
                    old_password: '',
                    new_password: '',
                    repeat_password: '',
                }
            }
        },
    }
</script>