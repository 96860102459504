<template>
    <div
        class="vendor__drivers-table-row table__row"
        :class="itemOpened ? 'is-open' : ''"
    >
        <div class="table__row-cell m--header m--mobile-show">
            {{ driver.name }}
            <div 
                class="vendor__table-row-slide table__row-slide" 
                @click.stop="slide"
            />
        </div>
        <div class="table__row-cell m--mobile-hide">
            <div class="vendor__drivers-table-row-title table__row-title">
                ФИО
            </div>
            {{ driver.name }}
        </div>
        <div class="table__row-cell">
            <div class="vendor__drivers-table-row-title table__row-title">
                Паспорт
            </div>
            {{ driver.passport }}
        </div>
        <div class="table__row-cell">
            <div class="vendor__drivers-table-row-title table__row-title">
                Водит. удостоверение
            </div>
            {{ driver.license }}
        </div>
        <div class="table__row-cell">
            <div class="vendor__drivers-table-row-title table__row-title">
                Телефон
            </div>
            {{ driver.phone }}
        </div>
        <div class="table__row-cell m--center">
            <div class="vendor__drivers-table-row-title table__row-title">
                Стаж, лет
            </div>
            {{ driver.experience }}
        </div>
        <div class="table__row-cell m--center">
            <div class="vendor__drivers-table-row-title table__row-title">
                Статус
            </div>
            {{ driver.publication ? 'Активен' : 'Блок' }}
        </div>

        <div class="table__row-cell m--center">
            <div class="vendor__drivers-table-row-title table__row-title">
                Ваш комментарий
            </div>
            {{ driver.comment }}
        </div>
        <div class="table__row-cell m--flex m--nopadding">
            <div
                class="m--icon m--gear"
                @click="$emit('editDriver', driver)"
            />
            <div
                :class="driver.publication ? 'm--icon m--lock-open' : 'm--icon m--lock-closed'"
                @click="$emit('setDriverStatus', driver.id, !driver.publication)"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            driver: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                itemOpened: false
            }
        },
        methods: {
            slide() {
                this.itemOpened = !this.itemOpened;
            }
        }
    }
</script>
