<template>
    <div class="app">
        <Navbar
            v-if="username"
            class="app__navbar"
        />
        <router-view
            class="app__main container"
        />
    </div>
</template>

<script>
    import Navbar from "@/components/Navbar";
    import { users } from "@/services";

    export default {
        name: 'App',
        components: {
            Navbar
        },
        computed: {
            username () {
                return this.$store.state.username;
            },
            role () {
                return this.$store.state.role;
            }
        },
        created() {
            if (this.$store.state.token && !this.$store.state.id) {
                users.getMyProfile().then((res) => {
                    this.$store.commit('setUserName', res.name)
                    this.$store.commit('setUserRole', res.is_manager)
                    this.$store.commit('setUserId', res.id)
                }).catch((error) => {
                    console.log(error, error.response);
                    this.commit('updateError', error.response.data);
                })
            }
        }
    }
</script>

<style>
    @import "assets/css/main.pcss";
</style>