const ajax = {
    timeout: 75000,
    responseType: 'json',
    responseEncoding: 'utf8'
};

const serviceUrl = {
    url: '//app.gerkules.com',
    port: '',
}

let urlPath = `${serviceUrl.url}${serviceUrl.port ? ':' + serviceUrl.port : ''}`;

const websocket = {
    url: '/ws/',
    protocols: []
};

const chat = {
    url: `${urlPath}/chat`,
    wsUrl: `wss://${urlPath}/ws/chat`,
    wsUrlPush: `${urlPath}/push`
};

const users = {
    url: `${urlPath}/users`,
    report: `${urlPath}/users/report`,
};

const app = {
    url: `${urlPath}/app`,
};

const country = {
    url: `${urlPath}/country`,
};

const navBar = [
    {
        link: '/tenders',
        name: 'tenders',
        role: 'all',
        icon: 'tender',
        title: 'Тендеры'
    }, {
        link: '/archive',
        name: 'archive',
        role: 'all',
        icon: 'archive',
        title: 'Архив'
    }, {
        link: '/news',
        name: 'news',
        role: 'all',
        icon: 'news',
        title: 'Новости'
    }, {
        link: '/vendors',
        name: 'vendors',
        role: 'manager',
        icon: 'vendors',
        title: 'Поставщики'
    }, {
        link: '/reports',
        name: 'reports',
        role: 'all',
        icon: 'reports',
        title: 'Отчеты'
    }, {
        link: '/notifications',
        name: 'notifications',
        role: 'all',
        icon: 'notifications',
        title: 'Уведомления'
    }, {
        link: '/chat',
        name: 'chat',
        role: 'all',
        icon: 'chat',
        title: 'Сообщения'
    }, {
        link: '/account',
        name: 'account',
        role: 'all',
        icon: 'profile',
        title: 'Профиль'
    }
]

export {
    ajax,
    urlPath,
    navBar,

    chat,
    users,
    app,
    country,

    websocket
}