import { users as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }

    static obtainToken(params) {
        return this._post('auth/obtain_token/', {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при получении токена', { request: { params } });
        });
    }

    static refreshToken(params) {
        return this._post('auth/refresh_token/', {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при обновлении токена', { request: { params } });
        });
    }

    static setNewPassword(old_password, new_password) {
        let params = {
            'old_password': old_password,
            'new_password': new_password
        }
        return this._post('auth/change_password/', {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static setCompanyData(id, address, post_address, inn, seo, phone, company_site) {
        let params = {
            "company_addr": address,
            "company_post_addr": post_address,
            "company_inn": inn,
            "company_phone": phone,
            "company_seo": seo,
            "company_site": company_site,
        };
        return this._patch(`company/${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static setUserData(id, name, email, phone, extra_phone, notify_email=false) {
        let params = {
            name: name,
            email: email,
            phone: phone,
            extra_phone: extra_phone,
            notify_email: notify_email
        }
        return this._patch(`${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static getMyProfile() {
        return this._get('me', {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора');
        });
    }

    static getProfile(id) {
        return this._get(`${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора');
        });
    }

    static getDocuments() {
        return this._get(`documents`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить документы');
        });
    }

    static getVendorDocuments(id) {
        return this._get(`vendordocuments/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить документы');
        });
    }

    static addDoc(id, params) {
        return this._post(`vendordocuments/${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static setDocActive(id, isActive) {
        let params = {
            'publication': isActive
        }
        return this._patch(`editdocuments/${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static updateDoc(id, params) {
        return this._patch(`editdocuments/${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static getDrivers() {
        return this._get(`drivers`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить водителей');
        });
    }

    static getVendorDrivers(id) {
        return this._get(`vendordrivers/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить водителей');
        });
    }

    static getVendors() {
        return this._get('', {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить поставщиков');
        });
    }
    static addVendor(params) {
        return this._post(``, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось добавить поставщика');
        });
    }

    static updateVendor(params) {
        return this._patch(`edit/${params.id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static setVendorActive(id, isActive) {
        let params = {
            company: {
                active: isActive
            }
        }
        return this._patch(`${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }
    static getCars() {
        return this._get('cars', {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить поставщиков');
        });
    }
    static getVendorCars(id) {
        return this._get(`vendorcars/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить поставщиков');
        });
    }

    static addDriver(params) {
        return this._post(`drivers`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось добавить водителя');
        });
    }

    static updateDriver(params) {
        return this._patch(`drivers/${params.id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static setDriverActive(id, isActive) {
        let params = {
            'publication': isActive
        }
        return this._patch(`drivers/${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static addCar(params) {
        return this._post(`cars`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось добавить водителя');
        });
    }

    static updateCar(params) {
        return this._patch(`cars/${params.id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static setCarActive(id, isActive) {
        let params = {
            'publication': isActive
        }
        return this._patch(`cars/${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static getUserCities() {
        return this._get(`cities`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить города');
        });
    }

    static getVendorCities(id) {
        return this._get(`vendorcities/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить города');
        });
    }


    static updateUserCities(cityIds) {
        let params = {
            'city_notices': cityIds
        }
        return this._patch(`cities`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static deleteUserCity(id) {
        return this._delete(`cities/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить города');
        });
    }

    static createReport(start_date, end_date) {
        let params = {
            'start_date': start_date,
            'end_date': end_date
        }
        return this._post(`reports`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить города');
        });
    }

    static getReports() {
        return this._get(`reports`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить города');
        });
    }

    static getFilters() {
        return this._get(`filters`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить фильтры');
        });
    }

    static delFilters(id) {
        return this._delete(`filter/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить фильтры');
        });
    }
    static addFilter(filter_string, filter_name) {
        let params = {
            'name': filter_name,
            'filter_string': filter_string,
        }
        return this._post(`filters`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось добавить фильтры');
        });
    }

}
