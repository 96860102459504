<template>
    <div class="archive">
        <FilterPanel />
        <div
            v-if="user_filters && user_filters.length > 0"
            class="filter m--collection"
        >
            <div
                v-for="item in user_filters"
                :key="item.id"
                class="filter__item"
                :class="filterActive(item) ? 'is-active': ''"
            >
                <span
                    class="filter__item-label"
                    @click="applyFilterFromToolbar(item)"
                >
                    {{ item.name }}
                </span>
                <span
                    class="filter__item-delete"
                    @click="deleteFilter(item.id)"
                />
            </div>
        </div>

        <ToolBar
            v-if="tenders.length > 0"
            ref="toolBar"
            :page="pageNum"
            :pgCount="pageCount"
        />
        <div
            v-if="tenders.length > 0"
            class="tender__items"
        >
            <TenderItem
                v-for="tender in tenders"
                :key="tender.id"
                :tender="tender"
                :archive="true"
            />
        </div>
        <div
            v-else
            class="tender__empty"
        >
            Не было найдено ни одного соответствия
        </div>
    </div>
</template>

<script>
    import FilterPanel from "@/components/FilterPanel";
    import ToolBar from "@/components/ToolBar";
    import TenderItem from "@/components/TenderItem";
    import { app, users } from "@/services";

    export default {
        name: "Archiv",
        components: {
            ToolBar,
            FilterPanel,
            TenderItem
        },
        data() {
            return {
                tenders: [],
                expanded: false,
                filter: null,
                user_filters: [],
                userFilter: undefined,
                pageNum: 1,
                pageCount: 0
            }
        },
        mounted() {
            this.fetchTenders(1);
            this.fetchFilters();
        },
        methods: {
            deleteFilter(id) {
                users.delFilters(id).then(() => {
                    this.fetchFilters()
                })
            },
            fetchFilters() {
                users.getFilters().then((res) => {
                    this.user_filters = res
                })
            },
            getTendersPerPage() {
                return this.$refs.toolBar && this.$refs.toolBar.tendersPerPage.value || 10;
            },
            fetchTenders(pageNum) {
                this.pageNum = pageNum || 1;
                const limit = this.getTendersPerPage();
                const offset = (this.pageNum - 1) * limit;
                app.getArchiveList(limit, offset, this.filter && this.filter.filter_string ? this.filter.filter_string : this.filter).then(res => {
                    if (res.count <= offset && this.pageNum > 1) {
                        /* Если пытаемся загрузить страницу, на которой по факту нет записей, то перебрасываем на первую */
                        this.fetchTenders(1, this.filter);
                        return null;
                    } else {
                        this.tenders = res.results;
                        this.pageCount = res.count;
                    }
                }).catch(err => console.error(err));

            },
            pageCountChanged() {
                this.fetchTenders(1, this.filter)
            },
            applyFilter(filter) {
                this.filter = filter;
                this.fetchTenders(1);
            },
            applyFilterFromToolbar(filter) {
                if (this.filter && this.filter.id === filter.id) {
                    this.clearActiveFilterStyles(true)
                    return;
                }
                this.userFilter = filter.id;
                this.applyFilter(filter);
            },
            clearActiveFilterStyles(tenders) {
                this.userFilter = 0;
                if (tenders) {
                    this.applyFilter();
                }
            },
            filterActive(filter) {
                return filter.id === this.userFilter;
            }
        },
    }
</script>
