<template>
    <div class="tabs">
        <div class="tabs__item">
            <router-link to="/account">
                Информация о компании
            </router-link>
        </div>
        <div class="tabs__item">
            <router-link to="/account/documents">
                Документы
            </router-link>
        </div>
        <div class="tabs__item">
            <router-link to="/account/drivers">
                Водители
            </router-link>
        </div>
        <div class="tabs__item">
            <router-link to="/account/cars">
                Машины
            </router-link>
        </div>
        <div class="tabs__item">
            <router-link to="/account/cities">
                Города
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AccountMenu"
    }
</script>
