import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist'
import jwt_decode from 'jwt-decode'
import { users } from '@/services'

const vuexPersist = new VuexPersist({
    key: 'gerkules',
    storage: window.localStorage
});

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: null,
        refreshToken: null,
        username: null,
        id: null,
        role: null,
        error: null
    },
    plugins: [vuexPersist.plugin],
    mutations: {
        setUserName(state, username) {
            console.log("Обновление состояния name")
            console.log(username)
            state.username = username;
        },
        setUserId(state, id) {
            console.log("Обновление состояния id")
            console.log(id)
            state.id = id;
        },
        setUserRole(state, role) {
            console.log("Обновление состояния role")
            console.log(role)
            state.role = role;
        },
        updateToken(state, tokens) {
            state.token = tokens.token;
            state.refreshToken = tokens.refreshToken;
        },
        removeToken(state) {
            state.token = null;
            state.refreshToken = null;
            state.username = null;
            state.role = null;
            state.id = null;
        },
        updateError(state, error) {
            state.error = error;
        }
    },
    getters: {
        getUserRole: state => state.role,
        getUserName: state => state.username,
        getUserId: state => state.id
    },
    actions: {
        obtainToken(tre, data) {
            this.commit('updateError', null);
            const payload = {
                username: data.username,
                password: data.password
            }
            users.obtainToken(payload).then((response) => {
                this.commit('updateToken', {
                    token: response.access,
                    refreshToken: response.refresh
                });
                users.getMyProfile().then((res) => {
                    this.commit('setUserName', res.name)
                    this.commit('setUserRole', res.is_manager)
                    this.commit('setUserId', res.id)
                }).catch((error) => {
                    console.log(error, error.response);
                    this.commit('updateError', error.response.data);
                })
            }).catch((error) => {
                console.log(error, error.response);
                this.commit('updateError', error.response.data);
                return error.response.data
            })
        },
        refreshToken() {
            const payload = {
                token: this.state.token,
                refresh: this.state.refreshToken
            }
            return users.refreshToken(payload)
                .then((response) => {
                    this.commit('updateToken', {
                        token: response.access,
                        refreshToken: response.refresh
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.commit('removeToken');
                    this.commit('updateError', error);
                })
        },
        inspectToken() {
            const token = this.state.token;

            if (token) {
                const decoded = jwt_decode(token);
                const exp = decoded.exp;
                if (exp <= (Date.now() / 1000)) {
                    return this.dispatch('refreshToken');
                }
                return token;
            } else {
                this.commit('removeToken');
                return null;
            }
        }
    }
});
