import WS from '@/utils/websocket';
import { chat as settings } from '@/settings';
import {REST, RESTError} from './rest';
import store from '@/store/store';

export default class extends REST {
    static get settings() {
        return settings;
    }
    get settings() {
        return settings;
    }
    get ws() {
        if (!this.__ws) {
            this.__ws = new WS({
                url: `${this.settings.wsUrl}/${store.state.id}/`,
            });
        }
        return this.__ws;
    }
    openChat() {
        this.ws.open();
    }
    closeChat(code, messages) {
        this.ws.close(code, messages);
    }
    onEvent(event, handler) {
        this.ws.on(event, handler);
    }
    offEvent(event, handler) {
        this.ws.off(event, handler);
    }
    sendMessage(data) {
        this.ws.send(data);
    }

    static getOrCreateTenderChat(tender_id, vendor_id) {
        let params = {
            'tender_id': tender_id,
            'vendor_id' :vendor_id
        }
        return this._post('get_or_create/', {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }

    static getChats() {
        return this._get(``, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить чаты');
        });
    }

    static getMessages(chatId, offset, limit) {
        let data = {
            'offset': offset,
            'limit': limit
        }
        return this._get(`${chatId}`, data).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при загрузке сообщений чата', { request: { data } });
        });
    }

    static sendMessage(message, room) {
        return this._post(`${room}/`, {}, message).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { message } });
        });
    }

    static getUnreadCount() {
        return this._get(`unread_count/`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора' );
        });
    }
}
