import Vue from 'vue';
import VueRouter from 'vue-router'
import store from '../store/store'

import login from "@/views/Login";

import Tenders from "@/views/Tenders";
import Tender from "@/views/Tender";

import Account from "@/views/Account";
import AccountDocs from "@/views/AccountDocs";
import AccountDrivers from "@/views/AccountDrivers";
import AccountCars from "@/views/AccountCars";
import AccountCities from "@/views/AccountCities";

import Archiv from "@/views/Archiv";
import News from "@/views/News";
import Reports from "@/views/Reports";
import Chat from "@/views/Chat";
import Notifications from "@/views/Notifications";

import Vendors from "@/views/Vendors";
import Vendor from "@/views/Vendor";
import VendorCars from "@/views/VendorCars";
import VendorCities from "@/views/VendorCities";
import VendorDocs from "@/views/VendorDocs";
import VendorDrivers from "@/views/VendorDrivers";

Vue.use(VueRouter);

const routes = [
    { path: "/", component: login, props: true},
    { name:'archive', path: "/archive", component: Archiv },
    { name:'news', path: "/news", component: News },
    { name:'chat', path: "/chat", component: Chat , props: route => ({ chatId: Number.parseInt(route.query.chatId )})},
    { name:'notifications', path: "/notifications", component: Notifications },
    { name:'reports', path: "/reports", component: Reports },
    { name:'account', path: "/account", component: Account },
    { path: "/account/info", component: Account },
    { path: "/account/documents", component: AccountDocs },
    { path: "/account/drivers", component: AccountDrivers },
    { path: "/account/cars", component: AccountCars },
    { path: "/account/cities", component: AccountCities },
    { name: 'tenders', path: "/tenders", component: Tenders },
    { name: 'tender', path: "/tenders/:id", component: Tender},
    { name: 'login', path: "/login", component: login, props: true },
    { name: 'vendors', path: "/vendors", component: Vendors },
    { name: 'vendorInfo', path: "/vendors/:id/info", component: Vendor, props: true },
    { name: 'vendorCars', path: "/vendors/:id/cars", component: VendorCars, props: true },
    { name: 'vendorDocs', path: "/vendors/:id/documents", component: VendorDocs, props: true },
    { name: 'vendorCities', path: "/vendors/:id/cities", component: VendorCities, props: true },
    { name: 'vendorDrivers', path: "/vendors/:id/drivers", component: VendorDrivers, props: true }
];



const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'is-subactive',
    linkExactActiveClass: 'is-active',
    routes
});


router.beforeEach((to, from, next) => {
    if (store.state.token !== null) {
        store.dispatch('inspectToken').then((token) => {
            token ? next() : next('/login')
        });
    } else if (to.path === '/login') {
        next()
    } else {
        next('/login')
    }
});

export default router;