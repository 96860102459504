import { app as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }

    static getTenderList(limit, offset, filter) {
        let params = Object.assign({}, filter, {
            offset: offset,
            limit: limit
        })
        return this._get(`tenders/?${ typeof filter === 'string' ? filter : ''}`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить тендеры');
        });
    }
    static getArchiveList(limit, offset, filter) {
        let params = Object.assign({}, typeof filter === 'string' ? {} : filter, {
            offset: offset,
            limit: limit
        })
        return this._get(`archive/${ typeof filter === 'string' ? '?' + filter : ''}`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить архивные тендеры');
        });
    }
    static getNewsList(limit, offset, filter) {
        let params = Object.assign({}, typeof filter === 'string' ? {} : filter, {
            offset: offset,
            limit: limit
        })
        return this._get(`news/${ typeof filter === 'string' ? '?' + filter : ''}`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить новости');
        });
    }
    static getNotificationsList() {
        return this._get(`notifications`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить оповещения');
        });
    }
    static getTender(id) {
        return this._get(`tenders/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить тендер');
        });
    }
    static getTenderBets(id) {
        return this._get(`tenders/${id}/bets`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить ставки тендера');
        });
    }

    static createBet(tender_id, params) {
        return this._post(`tenders/${tender_id}/bets`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке создании ставки', { request: { params } });
        });
    }
    static setupTender(tender_id, car, driver, time) {
        let params = Object.assign({},
            car ? {
                vendor_car: car
            } : null,
            driver ? {
                vendor_driver: driver
            } : null,
            time && time.time ? {
                time_delivery: {
                    date: time.date,
                    time: time.time
                }
            } : null,
        );
        return this._patch(`tenders/${tender_id}/setup`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при обновлении данных по тендеру', { request: { params } });
        });
    }
    static confirmTender(tender_id, status) {
        let params = {
            confirm: status
        }
        return this._post(`tenders/${tender_id}/confirm`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при проверке индентификатора', { request: { params } });
        });
    }
    static denyWinner(tender_id) {
        return this._post(`tenders/${tender_id}/remove_winner`, {}, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при отклонении победителя');
        });
    }
    static deleteLastBet(tender_id, user_id) {
        let params = {
            user_id: user_id
        }
        return this._post(`tenders/${tender_id}/delete_last_bet`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Ошибка при удалении ставки', { request: { params } });
        });
    }
    static getTimeDelivery(tender_id) {
        return this._get(`delivery_time/${tender_id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить время для тендера');
        });
    }
}
