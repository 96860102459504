<template>
    <input
        ref="input"
        :value="value"
        autocomplete="off"
        @change="change($event.target.value)"
        @focus="focus"
    >
</template>

<script>
    import Inputmask from 'inputmask';

    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            mask: {
                type: [ String, Object ],
                required: true
            },
            value: {
                type: [String, Number],
                default: ''
            }
        },
        watch: {
            mask() {
                this.unbind();
                this.bind();
            }
        },
        mounted() {
            this.bind();
        },
        beforeDestroy() {
            this.unbind();
        },
        methods: {
            change(value) {
                this.$emit('change', value);
            },
            bind() {
                Inputmask(this.mask).mask(this.$refs.input);
            },
            unbind() {
                Inputmask.remove([this.$refs.input]);
            },
            focus(value) {
                this.$emit('focus', value);
            }
        }
    };
</script>
