<template>
    <div class="chat-page">
        <div
            v-if="chats.length > 0"
            class="chat-page__list"
            :class="windowActive ? '' : 'is-active'"
        >
            <div
                v-for="item in sortedChats"
                :key="item.id"
                class="chat-page__list-item"
                :class="{'is-active': isActive(item.id)}"
                @click="selectChat(item.id, item)"
            >
                <div class="chat-page__list-block">
                    <div class="chat-page__list-item-name">
                        {{ getCollocutorName(item) }}
                    </div>
                    <div class="chat-page__list-item-time">
                        {{ item.last_update | date('HH:mm', true) }}
                    </div>
                </div>
                <div class="chat-page__list-block">
                    <div class="chat-page__list-item-tender">
                        {{ item.fullName }}
                    </div>
                    <div
                        v-if="item.unread_count > 0 && !item.is_readed"
                        class="chat-page__list-item-msg"
                    >
                        {{ item.unread_count }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="chat-page__list"
            :class="windowActive ? '' : 'is-active'"
        >
            <div class="chat-page__list-empty text">
                Здесь пока пусто
                <router-link :to="{ name: 'tenders' }">
                    Перейти в тендеры
                </router-link>
            </div>
        </div>
        <div
            v-if="chat"
            class="chat-page__window"
            :class="[windowActive ? 'is-active' : '', isLoading ? 'is-loading' : '']"
        >
            <loader :is-shown="isLoading" />
            <div 
                class="chat-page__window-close"
                @click.stop="closeWindow(0)"
            />
            <div class="chat-page__window-title h2">
                {{ collocutorName }}
            </div>
            <div
                ref="area"
                class="chat-page__window-messages"
                @wheel="scroll"
            >
                <div
                    v-if="chatEmpty && !isLoading"
                    class="chat-page__window-empty"
                >
                    Здесь будет отображаться ваша история сообщений.
                </div>
                <template
                    v-for="msg in messages"
                    v-else
                >
                    <div
                        v-if="msg.type === 'time'"
                        :key="msg.date"
                        class="chat-page__window-time"
                    >
                        {{ msg.date | date('DD.MM.YYYY', true) }}
                    </div>
                    <div
                        v-else
                        :key="msg.pk"
                        class="chat-page__message"
                        :class="{ readed: msg.is_readed, mine: isMine(msg.author) }"
                    >
                        <div class="chat-page__message-text">
                            {{ msg.text }}
                        </div>
                        <div class="chat-page__message-time msg-time">
                            {{ msg.timestamp | date('HH:mm', true) }}
                        </div>
                    </div>
                </template>
            </div>
            <form
                class="chat-page__form"
                @submit.prevent="sendMessage(message)"
            >
                <textarea
                    v-model="message"
                    class="chat-page__form-input textarea"
                    placeholder="Введите ваше сообщение…"
                    @keydown.enter.exact.prevent
                    @keyup.enter.exact="sendMessage(message)"
                />
                <button
                    class="chat-page__form-submit button"
                    @submit="sendMessage(message)"
                />
            </form>
        </div>
        <div
            v-else
            class="chat-page__window"
        >
            <div
                v-if="chatEmpty"
                class="chat-page__window-empty"
            >
                Выберите чат, чтобы начать общаться
            </div>
        </div>
    </div>
</template>

<script>
    import { chat as Chat } from '@/services';
    import loader from "@/components/loader";
    import _find from 'lodash/find';

    export default {
        name: "Chat",
        components: {
            loader
        },
        props: {
            chatId: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                chats: [],
                chat: null,
                chat_messages: [],
                myId: parseInt(this.$store.state.id),
                message: '',
                connection: null,
                limit: 15,
                offset: 0,
                collocutorName: '',
                windowActive: false,
                isLoading: false,
                canScroll: true,
                chatEmpty: true,
                touchStartX: 0,
                touchStartY: 0,
                touchEndX: 0,
                touchEndY: 0
            }
        },
        computed: {
            sortedChats: function() {
                return [...this.chats].sort((a, b) => {
                    if (!a.last_update || !b.last_update) {
                        return !a.last_update ? 1 : !b.last_update ? -1 : 0;
                    }
                    return (a.last_update > b.last_update) ? -1 : ((a.last_update > b.last_update) ? 1 : 0);
                });
            },
            messages: function () {
                let items = [...this.chat_messages];
                let length = items.length
                let date;
                for (let i = 0; i < length; i++) {
                    let message = items[i];
                    if (i === 0 || this.dateDiff(date, message.timestamp) > 0) {
                        items.splice(i, 0, {
                            type: 'time',
                            date: message.timestamp
                        });
                        i++;
                        length++;
                        date = message.timestamp;
                    }
                }
                return items;
            },
            swipeThreshold: function () {
                return Math.min(150, Math.floor(0.02 * (window.innerWidth || document.body.clientWidth)))
            },
            swipeLimit: function () {
                return Math.tan(45 * 1.5 / 180 * Math.PI);
            }
        },
        watch: {
            'chat': function (newVal, oldVal) {
                if (!oldVal && newVal > 0) {
                    this.$nextTick(() => {
                        const el = this.$refs.area;
                        this.addSwipeListener(el);
                    });
                }
            }
        },
        created() {
            this.fetchChats(this.chatId);
            this.connection = new Chat();

            this.connection.onEvent('open', () => {
                console.log('Chat is opened');
                this.isConnected = true;
            });
            this.connection.onEvent('close', (isOK, e) => {
                if (isOK) {
                    console.log('Chat is closed');
                } else {
                    console.warn(`Chat is closed with code ${e.code}: ${e.reason}`);
                }
                this.isConnected = false;
            });
            this.connection.onEvent('error', () => {
                console.error('Chat has received an error');
            });
            this.connection.onEvent('message', (data) => {
                console.log('Chat has received a message', data);
                this.handleMessage(data);
            });
            this.connection.openChat();
        },
        destroyed() {
            this.connection.closeChat();
        },
        methods: {
            isActive(id) {
                return id === this.chat;
            },
            findChat(id) {
                return _find(this.chats, { id });
            },
            dateDiff(oldDate, newDate) {
                let msPerDay = 8.64e7;
                let oldAsDate = new Date(oldDate);
                let newAsDate = new Date(newDate);
                oldAsDate.setHours(12,0,0);
                newAsDate.setHours(12,0,0);
                return Math.round( (newAsDate - oldAsDate) / msPerDay );
            },
            scroll: function (el) {
                let delta = Math.max(-1, Math.min(1, (el.wheelDelta || -el.detail)));
                if (el.target.scrollTop === 0 && delta === 1 && this.isLoading === false && this.canScroll) {
                    this.appendMessages(this.chat);
                }
            },
            sendMessage: function (text) {
                if (text) {
                    this.chatEmpty = false;
                    this.message = '';
                    let message = {
                        "text": text,
                        "author": this.myId,
                        "room": this.chat,
                    }
                    Chat.sendMessage(message, this.chat).then(() => {
                        this.fetchChats();
                    });
                }
            },
            getCollocutorName(chat) {
                const collocutor = chat.collocutors.find(c => c.id !== this.myId);
                return collocutor && collocutor.name;
            },
            handleMessage(msg) {
                if (msg.room === this.chat) {
                    msg.is_readed = true;
                    this.chat_messages.push(msg);
                    this.offset++;
                    if (msg.author !== this.$store.state.id) {
                        this.connection.sendMessage({
                            id: msg.id,
                            room: this.chat
                        })
                    }
                    const el = this.$refs.area;
                    this.$nextTick(() => {
                        el.scrollTop = el.scrollHeight;
                    });
                } else {
                    this.fetchChats();
                }
            },
            isMine(id) {
                return id === this.myId;
            },
            selectChat(chatId, item) {
                if (this.chat !== chatId) {
                    this.chat = chatId;
                    this.clearChat(chatId);
                    this.appendMessages(chatId);
                }
                if (item) {
                    this.windowActive = true;
                    this.collocutorName = this.getCollocutorName(item);
                }
            },
            fetchChats(chatId) {
                Chat.getChats().then(res => {
                    this.chats = res.results;
                    if (chatId) {
                        this.selectChat(chatId, this.findChat(chatId));
                    }
                }).catch(err => console.error(err));
            },
            appendMessages(chatId) {
                this.isLoading = true;
                Chat.getMessages(chatId, this.offset, this.limit).then(res => {
                    this.chat_messages = res.results.reverse().concat(this.chat_messages);
                    this.canScroll = res.count > this.offset;
                    this.chatEmpty = res.count === 0;

                    const el = this.$refs.area;
                    const scrollHeight = el.scrollHeight;
                    this.$nextTick(() => {
                        this.fetchChats();
                        this.offset += this.limit;
                        el.scrollTop = el.scrollHeight - scrollHeight;
                        this.isLoading = false;
                    });
                }).catch(err => {
                    console.error(err);
                    this.isLoading = false;
                });
            },
            addSwipeListener(el) {
                el.addEventListener('touchstart', (event) => {
                    this.touchStartX = event.changedTouches[0].screenX;
                    this.touchStartY = event.changedTouches[0].screenY;
                }, false);

                el.addEventListener('touchend', (event) => {
                    this.touchEndX = event.changedTouches[0].screenX;
                    this.touchEndY = event.changedTouches[0].screenY;
                    this.handleSwipe(this.touchStartX, this.touchStartY, this.touchEndX, this.touchEndY);
                }, false);
            },
            handleSwipe(touchStartX, touchStartY, touchEndX, touchEndY) {
                let x = touchEndX - touchStartX;
                let y = touchEndY - touchStartY;
                let xy = Math.abs(x / y);
                let yx = Math.abs(y / x);

                if (Math.abs(x) > this.swipeThreshold || Math.abs(y) > this.swipeThreshold) {
                    if (yx <= this.swipeLimit) {
                        if (x > 0) {
                            this.closeWindow(0)
                        }
                    }
                    if (xy <= this.swipeLimit) {
                        if (y > 0) {
                            this.bottomSwipe()
                        }
                    }
                }
            },
            bottomSwipe() {
                const el = this.$refs.area;
                if (el.scrollTop === 0 && this.isLoading === false && this.canScroll) {
                    this.appendMessages(this.chat);
                }
            },
            closeWindow() {
                this.windowActive = false;
            },
            clearChat() {
                this.offset = 0;
                this.chat_messages = [];
                this.chatEmpty = true;
                this.canScroll = true
            }
        }
    }
</script>
